/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getProductCategoryLimit} from '../../../api/apiCore';
import {UPLOADURL} from '../../../config'
import {Link} from 'react-router-dom'
import {AddCartItem} from '../../../api/Cart'
import {AddFavoritItem} from '../../../api/Favorites'
import Currency from '../InnerCommponets/Currency'
import {Toast} from 'react-bootstrap'
const Slider = (props)=>{
    const {history} = props
    const [products,setProducts] = useState([])
    const [fixedShow,setFixedShow] = useState(false)
    const getLastProducts = ()=>{
        getProductCategoryLimit(props.data.content.category)
        .then((data)=>{
            setProducts(data)
        })
    }

    useEffect(()=>{
        getLastProducts()
    },[])

    const AddNewItemToCart = (item)=>{
        AddCartItem(item,()=>{
            setFixedShow(true)
            history.push('/')
        })
    }

    const AddNewFavItem = (item)=>{
        AddFavoritItem(item,()=>{history.push('/')})
    }
    var arrayIncremnt = 0;
    const setSliderData = ()=>{ //product_discount_price , product_price
        var innerData = [];
        var allData = [];
        for(var i=0;i<products.length;i++){
            const cProduct = products[i]
            var price;
            if(products[i].product_discount_price){
                price = 
                <div className="prices">
                    <span className="old-price">{products[i].product_price} <Currency/></span>
                    <span className="product-price color-secondary">{products[i].product_discount_price} <Currency/></span>
                </div>
            }
            else{
                price = 
                <div className="prices">
                    <span className="product-price color-secondary">{products[i].product_price} <Currency/></span>
                </div>
            }
            var image
            if(products[i].productImages.length > 0){
                image = <img src={UPLOADURL + "/" + products[i].productImages[0].product_image} alt="" />
            }
            else{
                image = <img src="http://placehold.it/360x240" alt="" />
            }
            const productData = 
            <div className="col-lg-3 col-sm-6">
                <div className="card card-product product-promo card-img">
                    <figure>
                        {image}
                        <figcaption>
                            <ul className="d-flex justify-content-center">
                                {products[i].productAttributes.length === 0 && <li>
                                    <a onClick={()=>AddNewItemToCart(cProduct)} 
                                    className="btn btn--rounded btn-outline-light btn-sm">أضف للسلة</a></li>}
                                {products[i].productAttributes.length > 0 && <li><Link to={"/product/"+products[i].id} className="btn btn--rounded btn-outline-light btn-sm">تفاصيل المنتج</Link></li>}
                                    
                                    <li><a onClick={()=>AddNewFavItem(cProduct)} className="btn like-btn"><i className="la la-heart-o"></i></a></li>
                            </ul>
                        </figcaption>
                    </figure>
                    {/* <span className="badge badge-pill badge-primary">-49%</span> */}
                    <div className="card-body">
                        <h6 alt={products[i].product_title} title={products[i].product_title}><Link to={"/product/"+products[i].id}>{products[i].product_title}</Link></h6>
                        {price}
                    </div>
                </div>
            </div>
            innerData.push(productData) 
            
            if((i+1) === 4){
                allData.push(sliderWarapperActive(innerData))
                innerData = []
            }
            else if(i !== 4 && (i+1) % 4 === 0){
                allData.push(sliderWarapperUnActive(innerData))
                innerData = []
            }
            else if(i === (products.length - 1)){
                if(i <= 3){
                    allData.push(sliderWarapperActive(innerData))
                }
                else{
                    allData.push(sliderWarapperUnActive(innerData))
                }
            }
        }
        
        return allData
    }
    const sliderWarapperActive = (data)=>{
        return (
            <div className="carousel-item active">
                <div className="product-grid container-fluid">
                    <div className="row">
                        {data}
                    </div>
                </div>
            </div>
        )
    }
    const sliderWarapperUnActive = (data)=>{
        return (
            <div className="carousel-item">
                <div className="product-grid container-fluid">
                    <div className="row">
                        {data}
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        
        <section id={"carouselExampleIndicators-"+(props.index+1)} className="carousel slide p-top-10 p-bottom-80 carouselExampleIndicators" data-ride="carousel">
            {products.length > 0 &&
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div id="special" className="block-header">
                                <h3 className="block_header_title">{props.data.content.title}</h3>
                                <div className="block_header_divider"></div>


                                <a className="carousel-control-prev" href={"#carouselExampleIndicators-"+(props.index+1)} role="button" data-slide="prev">
                                <span className="las la-angle-left" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href={"#carouselExampleIndicators-"+(props.index+1)} role="button" data-slide="next">
                                <span className="las la-angle-right" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>



                            </div>
                        </div>
                    </div>
                    {products.length > 0 && 
                        <ol className="carousel-indicators">
                            {products.map((product,index)=>{
                                if(index == 0){
                                    return (<li data-target={"#carouselExampleIndicators-"+(props.index+1)} className="active" data-slide-to={arrayIncremnt}></li>)
                                }
                                else if(index % 4 == 0){
                                    arrayIncremnt += 1;
                                    return (<li data-target={"#carouselExampleIndicators-"+(props.index+1)} data-slide-to={arrayIncremnt}></li>)
                                }
                            })}
                        </ol>
                    }
                    {products.length && 
                        <div className="carousel-inner">
                            {setSliderData()}
                        </div>
                    }
                    <div>
                        <Toast onClose={() => setFixedShow(false)} show={fixedShow} delay={3000} autohide>
                            <Toast.Body className="addtocart">تم اضافته للسلة بنجاح!</Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
            }
        </section>
    )
}

export default Slider