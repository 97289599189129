/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn,getOrders} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated,signout} from '../../api/Auth'
import {Card} from 'react-bootstrap';
import {Table,Badge} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Currency from './InnerCommponets/Currency'

const Orders = ({history})=>{
    const [orderData,setOrderData] = useState([])

    const loadOrders = ()=>{
        getOrders().then((data)=>{
            console.log(data)
            setOrderData(data)
        })
    }
    useEffect(()=>{
        if(isAuthenticated() === false)
        history.push('/')
        loadOrders()
    },[])
    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">حسابي</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">الرئيسية</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">قائمة طلباتي</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-0 order-md-0 mb-4">
                                <div className="sidebar">
                                  
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">القائمة</h6>
                                            </div>
                                            <div className="widget-content pr-0 pl-0">
                                                <div className="category-widget">
                                                    <div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/dashboard">صفحة حسابي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/profile">تعديل بياناتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/orders">قائمة طلباتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/wishlist">قائمة أمنياتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/address">تعديل عنواني</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/password">كلمة المرور</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link onClick={()=>signout(()=>{history.push('/')})} style={{cursor:'pointer'}}>تسجيل الخروج</Link></div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 order-1 order-md-1">
                               
                            
                              
                                <div className="row">
                                    <div className="col-lg-12 col-md-11 order-3 order-md-0 mb-4">
                                        <div className="sidebar">
                                        
                                            <div className="widget-wrapper">
                                                <div className="widget-default">
                                                    <div className="widget-header">
                                                        <h6 className="widget-title">قائمة طلباتي</h6>
                                                    </div>
                                                    <div className="widget-content p-0">
                                                        <div className="category-widget">
                                                            <div>
                                                                {
                                                                    orderData.length === 0 && <div className="text-center p-4">لا يوجد طلبات</div>
                                                                }
                                                            {
                                                                orderData.length > 0 &&  
                                                                <Table responsive>
                                                                    <thead>
                                                                        <tr>
                                                                        <th width="1">#</th>
                                                                        <th>رقم الطلب</th>
                                                                        <th>تاريخ الطلب</th>
                                                                        <th>حالة الطلب</th>
                                                                        <th>اجمالي الطلب</th>
                                                                        <th>الخصم</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            orderData.map((order,index)=>{
                                                                                var discount = 0;
                                                                                if('order_coupon' in order && 'coupon_title' in order.order_coupon){
                                                                                    if(order.order_coupon.coupon_type === 'Fixed'){
                                                                                        //setShow(true)
                                                                                        discount =  order.order_coupon.coupon_type_value;
                                                                                    }
                                                            
                                                                                    else if(order.order_coupon.coupon_type === 'Percent'){
                                                                                        discount = ((order.totalcost * order.order_coupon.coupon_type_value ) / 100)
                                                                                    }
                                                                                }
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{index+1}</td>
                                                                                        <td><Link to={`/order/${order._id}`}>{"order_number" in order && order.order_number}#</Link></td>
                                                                                        <td>{new Date(order.createdAt).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                                                                        <td>
                                                                                            {order.order_status === "OnProgress" && <Badge pill variant="primary p-2">جاري العمل عليه</Badge>}
                                                                                            {order.order_status === "WaitingPayment" && <Badge pill variant="info p-2">في انتظار الدفع</Badge>}
                                                                                            {order.order_status === "Delivered" && <Badge pill variant="success p-2">تم التسليم</Badge>}
                                                                                            {order.order_status === "Canceled" && <Badge pill variant="danger p-2">تم الالغاء</Badge>}
                                                                                        </td>
                                                                                        <td>{order.totalcost} <Currency/></td>
                                                                                        <td>{discount}  <Currency/></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                       
                                                                    </tbody>
                                                                </Table>
                                                            
                                                            }    
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>


                    



                            </div>

                        </div>

                    </div>


                </section>



        </Layout>
    )    
}
export default Orders;