/* eslint-disable */
export const CartItems = ()=>{

    if(typeof window == 'undefined'){
        return false
    }


    if(localStorage.getItem('cartItems')){
        //return JSON.parse(localStorage.getItem('cartItems'))
        var items = []
        items = JSON.parse(localStorage.getItem('cartItems'))
        for(var i=0;i<items.length;i++){
            if(items[i].product_discount_price)
            {
                items[i].product_price = items[i].product_discount_price
                //items[i].product_price *= items[i].q
            }
            //check For Attributes
            
            for(var x = 0;x<items[i].productAttributes.length;x++)
            {
                for(var y=0;y<items[i].productAttributes[x].attributeValues.length;y++){
                    if(items[i].productAttributes[x].attributeValues[y].select === true){
                        items[i].product_price += items[i].productAttributes[x].attributeValues[y].attribute_value_extra_price
                        //items[i].product_price *= items[i].q
                    }
                }
            }
            
        }

        return items
    }
    else{
        localStorage.setItem('cartItems',JSON.stringify([]))
        localStorage.setItem('coupon',JSON.stringify({}))
        return false;
    }
}

export const CardTotal = ()=>{
    if(typeof window == 'undefined'){
        return false
    }


    if(localStorage.getItem('cartItems')){
        //return JSON.parse(localStorage.getItem('cartItems'))
        var items = []
        var total = 0;
        items = JSON.parse(localStorage.getItem('cartItems'))
        for(var i=0;i<items.length;i++){
            if(items[i].product_discount_price)
            {
                items[i].product_price = items[i].product_discount_price
            }
            //check For Attributes
            
            for(var x = 0;x<items[i].productAttributes.length;x++)
            {
                for(var y=0;y<items[i].productAttributes[x].attributeValues.length;y++){
                    if(items[i].productAttributes[x].attributeValues[y].select === true){
                        items[i].product_price += items[i].productAttributes[x].attributeValues[y].attribute_value_extra_price
                    }
                }
            }
            if(items[i].q === undefined){
                total += items[i].product_price
            }
            else{
                total += items[i].product_price * items[i].q
            }
            
        }
        const coupon = JSON.parse(localStorage.getItem('coupon'))
        if(JSON.stringify(coupon) === JSON.stringify({}) || !coupon){
            return total
        }
        else{
            if(coupon.coupon_min_amount > total) {
                localStorage.setItem('coupon',JSON.stringify({}))
            }
            else{
                return (total - coupon.discountValue)
            }
        }
        
    }
    else{
        return 0;
    }
}

export const CartTotalA = ()=>{
    if(typeof window == 'undefined'){
        return false
    }


    if(localStorage.getItem('cartItems')){
        //return JSON.parse(localStorage.getItem('cartItems'))
        var items = []
        var total = 0;
        items = JSON.parse(localStorage.getItem('cartItems'))
        for(var i=0;i<items.length;i++){
            if(items[i].product_discount_price)
            {
                items[i].product_price = items[i].product_discount_price
            }
            //check For Attributes
            
            for(var x = 0;x<items[i].productAttributes.length;x++)
            {
                for(var y=0;y<items[i].productAttributes[x].attributeValues.length;y++){
                    if(items[i].productAttributes[x].attributeValues[y].select === true){
                        items[i].product_price += items[i].productAttributes[x].attributeValues[y].attribute_value_extra_price
                    }
                }
            }
            if(items[i].q === undefined){
                total += items[i].product_price
            }
            else{
                total += items[i].product_price * items[i].q
            }
            
        }
        
        return total
        
        
    }
    else{
        return 0;
    }
}

export const RemoveCartItem = (index,next)=>{
    var items = []
    if(localStorage.getItem('cartItems')){
        items =  JSON.parse(localStorage.getItem('cartItems'))
        items.splice(index,1)

        localStorage.setItem('cartItems',JSON.stringify(items))
        
        next()
    }
    else{
        return false;
    }
}

export const AddMoreItems = (index,next)=>{
    var items = []
    if(localStorage.getItem('cartItems')){
        
        items =  JSON.parse(localStorage.getItem('cartItems'))
        items[index].q += 1;

        localStorage.setItem('cartItems',JSON.stringify(items))
        
        next()
    }
    else{
        return false;
    }
}

export const AddLessItems = (index,next)=>{
    var items = []
    if(localStorage.getItem('cartItems')){
        items =  JSON.parse(localStorage.getItem('cartItems'))
        console.log(items[index])
        if(items[index].q === 1)
        {
            items.splice(index,1)
        }
        else
        {
            items[index].q -= 1;
        }
        
        localStorage.setItem('cartItems',JSON.stringify(items))
        
        next()
    }
    else{
        return false;
    }
}

export const AddCartItem = (item,next)=>{

    if(typeof window == 'undefined'){
        return false
    }
    //localStorage.removeItem('cartItems')

    if(localStorage.getItem('cartItems')){
        var items = []
        items =  JSON.parse(localStorage.getItem('cartItems'))
        //check if item exits
        var exits = false
        var attributeExits = true
        //item.q = 1;
        for(var i=0;i<items.length;i++){
            if(items[i]._id === item._id){
                exits = true
                // if(item.productAttributes.length === 0){
                //     exits = true
                // }
                // else
                // {
                //     for(var x = 0;x<items[i].productAttributes.length;x++)
                //     {
                //         for(var y=0;y<items[i].productAttributes[x].attributeValues.length;y++){
                //             if(items[i].productAttributes[x].attributeValues[y].select === true && item.productAttributes[x].attributeValues[y].select === false){
                //                 attributeExits = false
                //             }
                //         }
                //     }
                //     if(attributeExits === true)
                //         exits = true
                // }
                    
            }
                
        }


        
        if(exits === false){
            item.q = 1
            items.push(item)
        }
            
        localStorage.setItem('cartItems',JSON.stringify(items))
        next()
    }
    else{
        item = [item]
        localStorage.setItem('cartItems',JSON.stringify(item))
        next()
    }
}

export const GetDiscount = ()=>{
    if(typeof window == 'undefined'){
        return false
    }

    if(localStorage.getItem('coupon')){
        const item = JSON.parse(localStorage.getItem('coupon'))
        return item;
    }
    else{
        return false
    }
}

export const AddDiscount = (item,next)=>{
    if(typeof window == 'undefined'){
        return false
    }

    localStorage.setItem('coupon',JSON.stringify(item))
    next()
    
}