/* eslint-disable */
import React from 'react'
import {BrowserRouter,Switch,Route} from 'react-router-dom'

import Home from './defaulttheme/components/Home'
import Categories from './defaulttheme/components/Categories'
import Product from './defaulttheme/components/Product'
import Login   from './defaulttheme/components/Login'
import Register from './defaulttheme/components/Register'
import ViewCart from './defaulttheme/components/ViewCart'
import Checkout from './defaulttheme/components/Checkout'
import CheckoutConfirm from './defaulttheme/components/CheckoutConfirm'
import Dashboard from './defaulttheme/components/Dashboard'
import Profile from './defaulttheme/components/Profile'
import Orders from './defaulttheme/components/Orders'
import Address from './defaulttheme/components/Address'
import Password from './defaulttheme/components/Password'
import Page from './defaulttheme/components/Page'
import WishList from './defaulttheme/components/Wishlist'
import Header from './defaulttheme/components/Header'
import Order  from './defaulttheme/components/Order'
import SearchResult from './defaulttheme/components/SearchResults'
import Brands from './defaulttheme/components/Brands'
import Error from './defaulttheme/components/Error'
import Notfound from './defaulttheme/components/Notfound'
import ForgotPassword from './defaulttheme/components/ForgotPassword'
import PaymentProcess from './defaulttheme/components/PaymentProcess'

// import HomePage from './test/test3/HomePage'
const Routes = ()=>{
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/"                          exact component = {Home} />
                <Route path="/categories/:categoryId"    exact component = {Categories} />
                <Route path="/product/:productId"        exact component = {Product} />
                <Route path="/signin"                    exact component = {Login} />
                <Route path="/signup"                    exact component = {Register} />
                <Route path="/forgotpassword"            exact component = {ForgotPassword} />
                <Route path="/viewcart"                  exact component = {ViewCart} />
                <Route path="/checkout"                  exact component = {Checkout} />
                <Route path="/checkoutconfirm"           exact component = {CheckoutConfirm} />
                <Route path="/dashboard"                 exact component = {Dashboard} />
                <Route path="/profile"                   exact component = {Profile} />
                <Route path="/orders"                    exact component = {Orders} />
                <Route path="/order/:orderId"            exact component = {Order} />
                <Route path="/payments_process"          exact component = {PaymentProcess}/> ///id=:status=paid&amount=1700&message=Succeeded%21"
                <Route path="/wishlist"                  exact component = {WishList} />
                <Route path="/address"                   exact component = {Address} />
                <Route path="/password"                  exact component = {Password} />
                <Route path="/page/:pageId/:title"       exact component = {Page} />
                <Route path="/searchResult/:search"      exact component = {SearchResult} />
                <Route path="/brands/:brandId"           exact component = {Brands} />
                <Route path="/error"                     exact component = {Error} />
                <Route path="/*"                         exact component = {Notfound} />
                
            </Switch>
        </BrowserRouter>
    )
}

export default Routes