/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect,Link} from 'react-router-dom'
import {authenticate,isAuthenticated} from '../../api/Auth'
const Login = ()=>{

    const [values,setValues] = useState({
        email:'',
        password:'',
        error:'',
        loading:false,
        redirectToReferrer:false
    })

    const emailValue = (value)=>{
        setValues({...values,email:value.target.value})
    }
    const passwordValue = (value)=>{
        setValues({...values,password:value.target.value})
    }

    const singInSubmit = ()=>{
        const email = values.email
        const password = values.password
        
        if(email !== '' && password !== ''){
            setValues({...values,loading:true})
            signIn({email:email,password:password})
            .then((data)=>{
                
                if(data.error){
                    setValues({...values,error:data.error,loading:false})
                }
                else{
                    //Store 
                    authenticate(data,()=>{
                        setValues({
                            ...values,
                            redirectToReferrer:true 
                        })
                    })
                }
            })
        }
    }
    const redirectUser = ()=>{
        if(isAuthenticated()){
            return <Redirect to="/" />
        }
    }
    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
            {redirectUser()}
            <div className="login-form d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-xl-6 p-top-130 p-bottom-130 center-c">
                            <div className="form-wrapper">
                                <div className="card card-shadow">
                                    <div className="card-header">
                                        <h4 className="text-center mb-3 mt-3">تسجيل الدخول</h4>
                                       
                                    </div>
                                    <div className="card-body">
                                    <p className="text-center color-danger">{values.error}</p>
                                        <form class="was-validated">
                                            <div className="form-group">
                                                <input required onChange={(text)=>emailValue(text)} type="text" placeholder="البريد الإلكتروني" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <input required onChange={(text)=>passwordValue(text)} type="password" placeholder="كلمة المرور" className="form-control" />
                                            </div>

                                            <div className="row px-3 mb-4">
                                                <Link to="/forgotpassword" className="mr-auto mb-0 text-sm">نسيت كلمة المرور؟</Link>
                                            </div>

                                            <div className="form-group text-center m-bottom-20">
                                                {values.loading === false && 
                                                    <button  onClick={()=>singInSubmit()} className="btn btn-secondary" type="button">الدخول</button>
                                                }
                                                
                                            </div>
                                        </form>
                                        <p className="text-center m-bottom-25 m-top-25">لا يوجد لك حساب لدينا؟ <a href="/signup">انشاء حساب جديد</a></p>
                                        <div className="d-flex other-logins justify-content-center">
                                            {/* <a href=""><span className="fab fa-facebook-f"></span> Facebook</a>
                                            <a href=""><span className="fab fa-google-plus-g"></span> Google</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )    
}
export default Login;