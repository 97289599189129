/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getProductSpecial} from '../../../api/apiCore';
import {UPLOADURL} from '../../../config'
import {Link} from 'react-router-dom'
import {AddCartItem} from '../../../api/Cart'
import {AddFavoritItem} from '../../../api/Favorites'
import Currency from '../InnerCommponets/Currency'
import {Toast} from 'react-bootstrap'
const GridSpecial = (props)=>{
    const {history} = props
    const [products,setProducts] = useState([])
    const [fixedShow,setFixedShow] = useState(false)
    const getLastProducts = ()=>{
        getProductSpecial({blockType:props.data.type,limit:props.data.content.num})
        .then((data)=>{
            setProducts(data)
        })
    }

    useEffect(()=>{
        getLastProducts()
    },[])

    const AddNewItemToCart = (item)=>{
        AddCartItem(item,()=>{
            setFixedShow(true)
            history.push('/')
        })
    }

    const AddNewFavItem = (item)=>{
        AddFavoritItem(item,()=>{history.push('/')})
    }

    const productsData = ()=>{
        var allData = []
        var blockContainer = []
        var rowData = []

        var singleProduct;
        //var cProduct = {}
        var innerContainer = 0;
        for(var i=0;i<products.length;i++){
            if(i > 9)
                continue
            const cProduct = products[i]
            if(i === 0 || i % 5 === 0){
                //allData.push(singleProduct(products[i]))
                var price
                var image
                if(products[i].product_discount_price){
                    price = 
                    <div className="prices">
                        <span className="old-price">{products[i].product_price} <Currency/></span>
                        <span className="product-price color-secondary">{products[i].product_discount_price} <Currency/></span>
                    </div>
                }
                else{
                    price = 
                    <div className="prices">
                        <span className="product-price color-secondary">{products[i].product_price} <Currency/></span>
                    </div>
                }
                if(products[i].productImages.length > 0){
                    image = <img src={UPLOADURL + "/" + products[i].productImages[0].product_image} alt="" />
                }
                else{
                    image = <img src="http://placehold.it/360x240" alt="" />
                }
                singleProduct = 
                <div className="col-lg-6 col-sm-12">
                    <div className="card card-product minh card-imgb">
                        <figure>
                            {image}
                            <figcaption>
                                <ul className="d-flex justify-content-center">
                                {products[i].productAttributes.length === 0 && <li>
                                    <a onClick={()=>AddNewItemToCart(cProduct)} 
                                    className="btn btn--rounded btn-outline-light btn-sm">أضف للسلة</a></li>}
                                {products[i].productAttributes.length > 0 && <li><Link to={"/product/"+products[i].id} className="btn btn--rounded btn-outline-light btn-sm">تفاصيل المنتج</Link></li>}
                                    
                                    <li><a onClick={()=>AddNewFavItem(cProduct)} className="btn like-btn"><i className="la la-heart-o"></i></a></li>
                                </ul>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <h6 alt={products[i].product_title} title={products[i].product_title}><Link to={"/product/"+products[i].id}>{products[i].product_title}</Link></h6>
                            {price}
                        </div>
                    </div>
                </div>
                allData.push(singleProduct)
                //blockContainer.push(singleProduct)
            }
            else
            {
                innerContainer++
                var price
                var image
                if(products[i].product_discount_price){
                    price = 
                    <div className="prices">
                        <span className="old-price">{products[i].product_price} <Currency/></span>
                        <span className="product-price color-secondary">{products[i].product_discount_price} <Currency/></span>
                    </div>
                }
                else{
                    price = 
                    <div className="prices">
                        <span className="product-price color-secondary">{products[i].product_price} <Currency/></span>
                    </div>
                }
                if(products[i].productImages.length > 0){
                    image = <img src={UPLOADURL + "/" + products[i].productImages[0].product_image} alt="" />
                }
                else{
                    image = <img src="http://placehold.it/360x240" alt="" />
                }    
                singleProduct = 
                    <div className="card card-product card-img">
                        <figure>
                            {image}
                            <figcaption>
                            <ul className="d-flex justify-content-center">
                                {products[i].productAttributes.length === 0 && <li>
                                    <a onClick={()=>AddNewItemToCart(cProduct)} 
                                    className="btn btn--rounded btn-outline-light btn-sm">أضف للسلة</a></li>}
                                {products[i].productAttributes.length > 0 && <li><Link to={"/product/"+products[i].id} className="btn btn--rounded btn-outline-light btn-sm">تفاصيل المنتج</Link></li>}
                                    
                                    <li><a onClick={()=>AddNewFavItem(cProduct)} className="btn like-btn"><i className="la la-heart-o"></i></a></li>
                                </ul>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <h6 alt={products[i].product_title} title={products[i].product_title}><Link to={"/product/"+products[i].id}>{products[i].product_title}</Link></h6>
                            {price}
                        </div>
                    </div>
                rowData.push(singleProduct) 
                if(( innerContainer % 2 === 0) || (i+1) === products.length)
                {
                    var container = <div className="col-lg-3 col-sm-6">{rowData}</div>
                    allData.push(container)
                    rowData = []
                }  

                // if((i+1) === products.length || i % 4 === 0){
                //     //allData.push(<div className="row">{blockContainer}</div>)
                //     allData.push(blockContainer)
                //     blockContainer = []
                // }
                
                
            }
        }
        
        return allData
    }

    
    const doubleProduct = (data)=>{

    }

    return(
        <section className="shop-features p-top-10 p-bottom-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div class="block-header">
                                <h3 className="block_header_title">{props.data.content.title}</h3>
                                <div className="block_header_divider"></div>
                            </div>
                        </div>
                    </div>

                    <div className="product-grid container">
                        <div className="row">
                            {productsData()}
                            <div>
                                <Toast onClose={() => setFixedShow(false)} show={fixedShow} delay={3000} autohide>
                                    <Toast.Body className="addtocart">تم اضافته للسلة بنجاح!</Toast.Body>
                                </Toast>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GridSpecial