/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getCategories} from '../../api/apiCore'
import Layout from '../Layout'
import {getproductsCategory,getproductsSearchSort,getSingleCategory,getproductsSearchCount} from '../../api/apiCore'
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
import {AddCartItem} from '../../api/Cart'
import {AddFavoritItem} from '../../api/Favorites'
import RangeSlider from 'react-bootstrap-range-slider';
import {Form} from 'react-bootstrap';
import Currency from './InnerCommponets/Currency'

const SearchResult = ({match,history})=>{
    //console.log(match.params.categoryId)
    const categoryId = match.params.search

    const [productsCategory,setProductsCategory] = useState([])

    const [currentCategoryId,setCurrentCateogryId] = useState(categoryId)
    
    const [productCount,setProudctsCount] = useState(0)
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(12)
    const [sort,setSort]   = useState("DESC")
    const getStateCategoryProduct = ()=>{
        getproductsSearchSort(categoryId,skip,limit,sort).then((data)=>{
            console.log(data)
            setProductsCategory(data)
        })
    }

    //const [category,setCategory]     = useState({})
    const [categories,setCategories] = useState([]) 
    const [error,setError]           = useState(false)
    const [isLoading,setLoading]     = useState(false)
    const loadCategories = ()=>{
        setLoading(true)
        getCategories().then((data)=>{
            if(data.error){
                setError(data.error)
            }
            else{
                setCategories(data)
            }
            setLoading(false)
        })
    }
    // const getCurrentCategory = ()=>{
    //     getSingleCategory(categoryId).then((data)=>{
    //         setCategory(data)
    //     })
    // }
    // useEffect(()=>{
        
        
    // },[])

    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const getProductsCount = ()=>{
        getproductsSearchCount(categoryId).then((data)=>{
            setProudctsCount(data.length)

            var pagesLength = (data.length % 12 == 0 ? (data.length / 12) : ( ((data.length - (data.length % 12))  / 12 ) + 1))
            console.log("Lenght" +pagesLength)
            var pagesArray  = []
            if(pagesLength > 1){
                for(var i=0;i<pagesLength;i++){
                    pagesArray.push(i+1);
                }
                setCurrentPage(1)
                setPages(pagesArray)
            }
            
        })
    }
    if(currentCategoryId !== categoryId)
        setCurrentCateogryId(categoryId)

    useEffect(()=>{
        loadCategories()
        getStateCategoryProduct()
        //getCurrentCategory()
        getProductsCount()
    },[currentCategoryId,sort])

    const nextPage = (pageNumber)=>{

        getproductsSearchSort(categoryId,(pageNumber-1)*12,limit,sort).then((data)=>{
            if(data.error)
                console.log(data.error)
            setProductsCategory(data)
            setCurrentPage(pageNumber)
            console.log(data) 
        })
    }
    
    const changeSort = (e)=>{
        setSort(e.target.value)
        //getStateCategoryProduct()
    }
   

    const [ value, setValue ] = React.useState(50);
    const [ finalValue, setFinalValue ] = React.useState(null);

   
    return (
        <Layout title="نتائج البحث" description="Home Page Description" className="container">
            <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                <h4 className="page_title">{"نتائج البحث"}</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{"نتائج البحث"}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {productsCategory.length > 0 && 
                <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-1 order-md-0">
                                <div className="sidebar">
                                    {/* <div className="d-none d-lg-block">
                                        <div className="widget-wrapper">
                                            <div className="search-widget">
                                                <form action="#">
                                                    <div className="input-group">
                                                        <input type="text" className="fc--rounded" placeholder="البحث" />
                                                        <button type="submit"><i className="la la-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">الأقسام</h6>
                                            </div>
                                            <div className="widget-content">
                                                <div className="category-widget">
                                                <ul className="list-unstyled components">

                                                    {categories.map((category,i)=>{
                                                        let catData = []
                                                        
                                                        if(category.children.length > 0 && category.children){
                                                            category.children.map((catD,catDIndex)=>{
                                                                catData.push(
                                                                    <li>
                                                                        <Link to={"/categories/"+catD._id}>{catD.category_title}</Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        return (
                                                            <li>
                                                                <Link to={"/categories/"+category._id}>{category.category_title}</Link>
                                                                {category.children.length > 0 && category.children !== undefined &&
                                                                    <a href={"#pageSubmenu"+category._id} data-toggle="collapse" aria-expanded="false" className="menu_arrow"><i className="la la-angle-down"></i></a>
                                                                }
                                                                {category.children.length > 0 && category.children !== undefined &&  <ul className="collapse list-unstyled" id={"pageSubmenu"+category._id}>
                                                                        {catData}
                                                                    </ul>}
                                                            </li>        
                                                        )
                                                    })}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">تصفية حسب السعر</h6>
                                            </div>
                                            <div className="widget-content">
                                                <div className="price-filter">
                                                    <div className="range-slider text-center">


                                                 
                                                        <RangeSlider
                                                            value={value}
                                                            onChange={e => setValue(e.target.value)}
                                                            onAfterChange={e => setFinalValue(e.target.value)}
                                                            />
                                                            <div className="text-right">أعلى سعر: {finalValue}</div>
                                                    

                                              
                                                        <p>
                                                            <button type="submit" className="btn filter-btn btn--rounded btn-secondary">تصفية</button>
                                                            <input type="text" id="amount1" className="amounts" readOnly />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 order-0 order-md-1">
                                <div className="d-lg-none">
                                    <div className="widget-wrapper">
                                        <div className="search-widget">
                                            <form action="#">
                                                <div className="input-group">
                                                    <input type="text" className="fc--rounded" placeholder="Search" />
                                                    <button type="submit"><i className="la la-search"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-sort d-flex justify-content-between align-items-center">
                                    <div className="sort d-flex align-items-center">
                                        <span className="m-right-15">ترتيب حسب: </span>
                                        <div className="form-group">
                                            <div className="select-basic">
                                                <select className="form-control fc--rounded" onChange={(e)=>changeSort(e)} value={sort}>
                                                    <option value="DESC">ترتيب افتراضي</option>
                                                    <option value="LowestPrice">الأقل السعر</option>
                                                    <option value="HeighestPrice">الأعلى سعر</option>
                                                    <option value="ASC"> من الاقدم الي الاحدث</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="total-pages text-left">
                                        <p>عرض <span>1</span> - <span>9</span> نتائج</p>
                                    </div> */}
                                </div>
                                <div className="product-grid">
                                    <div className="row">
                                        {
                                            productsCategory.map((product,index)=>{
                                                return (
                                                    <div className="col-lg-4 col-sm-6">
                                                        <div className="card card-product product-promo">
                                                            <figure>
                                                                {product.productImages.length > 0 && <img src={UPLOADURL + "/" + product.productImages[0].product_image} alt="" />} 
                                                                {product.productImages.length === 0 && <img src="http://placehold.it/360x240" alt="" /> }
                                                                {/* <img src="http://placehold.it/360x240" alt="" /> */}
                                                                <figcaption>
                                                                    <ul className="d-flex justify-content-center">
                                                                        {product.productAttributes.length === 0 && 
                                                                            <li><span onClick={()=>AddCartItem(product,()=>{
                                                                                history.push('/categories/'+currentCategoryId)
                                                                                console.log(history)
                                                                            })} className="btn btn--rounded btn-outline-light">أضف للسلة</span></li>
                                                                        }

                                                                        {product.productAttributes.length > 0 && <li><Link to={"/product/"+product.id} className="btn btn--rounded btn-outline-light btn-sm">تفاصيل المنتج</Link></li>}
                                                                        
                                                                        <li><a onClick={()=>AddFavoritItem(product,()=>{history.push('/categories/'+currentCategoryId)})} className="btn like-btn"><i className="la la-heart-o"></i></a></li>
                                                                    </ul>
                                                                </figcaption>
                                                            </figure>
                                                            {/* <span className="badge badge-pill badge-primary">-49%</span> */}
                                                            <div className="card-body">
                                                                <h6><Link to={"/product/"+product.id}>{product.product_title}</Link></h6>
                                                                <div className="prices">
                                                                    {product.product_discount_price &&
                                                                        <React.Fragment>
                                                                            <span className="old-price">{product.product_price} <Currency/></span>
                                                                            <span className="product-price color-secondary">{product.product_discount_price} <Currency/></span>
                                                                        </React.Fragment>
                                                                    }
                                                                    {product.product_discount_price === undefined &&
                                                                        <span className="product-price color-secondary">{product.product_price} <Currency/></span>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                       
                                    </div>
                                    
                                    <div className="product-pagination m-top-20">
                                        <div className="pagination-area">
                                             <nav aria-label="Page navigation pagination-left">
                                                <ul class="pagination justify-content-center">
                                                    {
                                                        pages.map((page,index)=>{
                                                            if(page === currentPage)
                                                                return <li class="page-item active"><a class="page-link" >{page}<span class="sr-only">(current)</span></a></li>
                                                            return (
                                                                <li class="page-item"><a class="page-link" onClick={()=>nextPage(page)} key={"pages-"+index}>{page}</a></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {productsCategory.length === 0 && 
            <div className="container">
                <div className="row">
                    <div className="col-md-12 alerts">
                        <div className="text-center">
                        <i class="las la-exclamation-circle"></i>
                        </div>
                        <div className="text-center">
                             لاتوجد نتائج </div>
                        </div>
                </div>
            </div>
            }
        </Layout>
    )
}

export default SearchResult