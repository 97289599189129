/* eslint-disable */
import React,{useState,useEffect} from 'react';
import CloseLayout from '../CloseLayout'
const Home = ({match,location,history})=> {
    
    return (
        <CloseLayout title="" description="" className="container">
            
        </CloseLayout>
    );
}
  
export default Home