/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {Form} from 'react-bootstrap';
import {Alert} from 'react-bootstrap';

import {CartItems} from '../../api/Cart'
import {UPLOADURL} from '../../config'

const CheckoutConfirm = ()=>{



return (
    <Layout title="Home Page" description="Home Page Description" className="container">
            
            
    
            <section className="breadcrumb_area breadcrumb1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                    <h4 className="page_title">تنفيذ الطلب</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                             <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">تنفيذ الطلب</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="checkout-wrapper p-top-90 p-bottom-110">
                    <div className="container">
                        <div className="row">


                            <div className="col-lg-6 text-center center-c">
                                <h2>شكراً لك!</h2>
                                <p>تم تقديم طلبك وستتم معالجته في أقرب وقت ممكن. ستتلقى رسالة بريد إلكتروني بعد قليل بتأكيد طلبك.</p>
                            

                                <a href="/" className="btn btn-primary"> عودة لاكمال التسوق</a>
                            </div>






                     

                 

                   
                         </div>

                    </div>
                </section>



        </Layout>
    )
}

export default CheckoutConfirm