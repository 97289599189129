/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated,signout} from '../../api/Auth'
import {FavoritItems,RemoveFavoritItem} from '../../api/Favorites'
import {Card} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
const WishList = ({history})=>{
    const removeItem = (itemIndex)=>{
        console.log(itemIndex)
    }

    useEffect(()=>{
        if(isAuthenticated() === false)
        history.push('/')

    },[])

    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">حسابي</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">الرئيسية</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">قائمة طلباتي</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-0 order-md-0 mb-4">
                                <div className="sidebar">
                                  
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">القائمة</h6>
                                            </div>
                                            <div className="widget-content pr-0 pl-0">
                                                <div className="category-widget">
                                                    <div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/dashboard">صفحة حسابي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/profile">تعديل بياناتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/orders">قائمة طلباتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/wishlist">قائمة أمنياتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/address">تعديل عنواني</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/password">كلمة المرور</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link onClick={()=>signout(()=>{history.push('/')})} style={{cursor:'pointer'}}>تسجيل الخروج</Link></div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 order-1 order-md-1">
                               
                            
                              
                                <div className="row">
                                    <div className="col-lg-12 col-md-11 order-3 order-md-0 mb-4">
                                        <div className="sidebar">
                                        
                                            <div className="widget-wrapper">
                                                <div className="widget-default">
                                                    <div className="widget-header">
                                                        <h6 className="widget-title">قائمة أمنياتي</h6>
                                                    </div>
                                                    <div className="widget-content pr-0 pl-0">
                                                        <div className="category-widget">
                                                            <div>
                                                            {
                                                                FavoritItems() !== false &&
                                                                <Table responsive>
                                                                    <thead>
                                                                        <tr>
                                                                            <th> صورة </th>
                                                                            <th> المنتج</th>
                                                                            <th>السعر</th>
                                                                            <th>حذف</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            FavoritItems().map((item,index)=>{
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item.productImages.length > 0 && <img width={100} src={UPLOADURL + "/" + item.productImages[0].product_image} alt="" />} 
                                                                                            {item.productImages.length === 0 && <img width={100} src="http://placehold.it/360x240" alt="" /> }
                                                                                        </td>
                                                                                        <td><Link to={"product/"+item._id}>{item.product_title}</Link></td>
                                                                                        <td>{item.product_price}</td>
                                                                                        <td><div className="text-center" style={{cursor:'pointer'}} onClick={()=>RemoveFavoritItem(index,()=>{history.push('/wishlist')})}>X</div></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                    </tbody>
                                                                </Table>
                                                            }
                                                            {
                                                                FavoritItems() === false && 
                                                                <div className="text-center">لا يوجد منتجات في القائمة</div>
                                                            }
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>





                            </div>

                        </div>

                    </div>


                </section>



        </Layout>
    )    
}
export default WishList;