/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getProductSpecial} from '../../../api/apiCore';
import {UPLOADURL} from '../../../config'
import {Link} from 'react-router-dom'
import {AddCartItem} from '../../../api/Cart'
import {AddFavoritItem} from '../../../api/Favorites'
import Currency from '../InnerCommponets/Currency'
import {Toast} from 'react-bootstrap'

const FixedSpecial = (props)=>{
    const [products,setProducts] = useState([])
    const {history} = props
    const [fixedShow,setFixedShow] = useState(false)
    const getLastProducts = ()=>{
        getProductSpecial({blockType:props.data.type,limit:props.data.content.num})
        .then((data)=>{
            console.log(data)
            setProducts(data)
        })
    }

    useEffect(()=>{
        getLastProducts()
    },[])
    const AddNewItemToCart = (item)=>{
        AddCartItem(item,()=>{
            setFixedShow(true)
            history.push('/')
        })
    }

    const AddNewFavItem = (item)=>{
        AddFavoritItem(item,()=>{history.push('/')})
    }
    return(
        <section className="shop-products p-top-10 p-bottom-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div class="block-header">
                                <h3 className="block_header_title">{props.data.content.title}</h3>
                                <div className="block_header_divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="product-grid">
                            <div className="row">
                                {
                                    products.map((product,index)=>{
                                        var price
                                        var image
                                        if(product.product_discount_price){
                                            price = 
                                            <div className="prices">
                                                <span className="old-price">{product.product_price} <Currency/></span>
                                                <span className="product-price color-secondary">{product.product_discount_price} <Currency/></span>
                                            </div>
                                        }
                                        else{
                                            price = 
                                            <div className="prices">
                                                <span className="product-price color-secondary">{product.product_price} <Currency/></span>
                                            </div>
                                        }
                                        if(product.productImages.length > 0){
                                            image = <img src={UPLOADURL + "/" + product.productImages[0].product_image} alt="" />
                                        }
                                        else{
                                            image = <img src="http://placehold.it/360x240" alt="" />
                                        }
                                        return (
                                            <div className="col-lg-3 col-sm-6">
                                                <div className="card card-product card-img">
                                                    <figure>
                                                        {/* {product.productImages.length > 0 && <img src={UPLOADURL + "/" + product.productImages[0].product_image} alt="" />} 
                                                        {product.productImages.length === 0 && <img src="http://placehold.it/360x240" alt="" /> } */}
                                                        {image}
                                                        <figcaption>
                                                            <ul className="d-flex justify-content-center">
                                                                {product.productAttributes.length === 0 && <li>
                                                                    <a onClick={()=>AddNewItemToCart(product)} 
                                                                    className="btn btn--rounded btn-outline-light btn-sm">أضف للسلة</a></li>}
                                                                {product.productAttributes.length > 0 && <li><Link to={"/product/"+product.id} className="btn btn--rounded btn-outline-light btn-sm">تفاصيل المنتج</Link></li>}
                                                                    
                                                                <li><a onClick={()=>AddNewFavItem(product)} className="btn like-btn"><i className="la la-heart-o"></i></a></li>
                                                            </ul>
                                                            
                                                        </figcaption>
                                                    </figure>
                                                    <div className="card-body">
                                                        <h6 alt={product.product_title} title={product.product_title}><Link to={"/product/"+product.id}>{product.product_title}</Link></h6>
                                                        {price}
                                                    </div>
                                                </div>
                                            </div>
                                        )        
                                    })
                                }
                                <div>
                                    <Toast onClose={() => setFixedShow(false)} show={fixedShow} delay={3000} autohide>
                                        <Toast.Body className="addtocart">تم اضافته للسلة بنجاح!</Toast.Body>
                                    </Toast>
                                </div>
                            </div> 
                            {/* <div className="text-center m-top-30">
                                <a href="#" className="btn btn-outline-secondary">استعراض المزيد</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
}

export default FixedSpecial