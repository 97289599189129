/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getBrands} from '../../../api/apiCore';
import {UPLOADURL} from '../../../config'
import {Link} from 'react-router-dom'
const Brands = (props)=>{
    const [brands,getStateBrands] = useState([])
    const loadBrands = ()=>{
        getBrands().then((data)=>{
            if(data.error)
                console.log(data.error)
            else{
                console.log(data)
                getStateBrands(data)
            }
                    
        })
    }
    useEffect(()=>{
        loadBrands()
    },[])

    var arrayIncremnt = 0;
    const setSliderData = ()=>{ //product_discount_price , product_price
        var innerData = [];
        var allData = [];
        for(var i=0;i<brands.length;i++){
            
            
            const brandsData = 
            <div className="col-lg-2 col-sm-6">
                <div className="card card-product">
                    <figure>
                        <Link to={`brands/${brands[i]._id}`}><img src={UPLOADURL + "/" + brands[i].brand_image} alt={brands[i].brand_title} title={brands[i].brand_title} /></Link>
                    </figure>
                </div>
            </div>
            innerData.push(brandsData) 
            
            if((i+1) === 6){
                allData.push(sliderWarapperActive(innerData))
                innerData = []
            }
            else if(i !== 6 && (i+1) % 6 === 0){
                allData.push(sliderWarapperUnActive(innerData))
                innerData = []
            }
            else if(i === (brands.length - 1)){
                if(i <= 5){
                    allData.push(sliderWarapperActive(innerData))
                }
                else{
                    allData.push(sliderWarapperUnActive(innerData))
                }
            }
        }
        
        return allData
    }
    const sliderWarapperActive = (data)=>{
        return (
            <div className="carousel-item active">
                <div className="product-grid container-fluid">
                    <div className="row">
                        {data}
                    </div>
                </div>
            </div>
        )
    }
    const sliderWarapperUnActive = (data)=>{
        return (
            <div className="carousel-item">
                <div className="product-grid container-fluid">
                    <div className="row">
                        {data}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <section id={"carouselExampleIndicators-"+(props.index+1)} className="carousel slide p-top-10 p-bottom-80 carouselExampleIndicators" data-ride="carousel">
            {brands.length > 0 &&
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div id="special" className="block-header">
                                <h3 className="block_header_title">العلامات التجارية</h3>
                                <div className="block_header_divider"></div>


                                <a className="carousel-control-prev" href={"#carouselExampleIndicators-"+(props.index+1)} role="button" data-slide="prev">
                                <span className="las la-angle-left" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href={"#carouselExampleIndicators-"+(props.index+1)} role="button" data-slide="next">
                                <span className="las la-angle-right" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>



                            </div>
                        </div>
                    </div>
                    {brands.length > 0 && 
                        <ol className="carousel-indicators">
                            {brands.map((product,index)=>{
                                if(index == 0){
                                    return (<li data-target={"#carouselExampleIndicators-"+(props.index+1)} className="active" data-slide-to={arrayIncremnt}></li>)
                                }
                                else if(index % 6 == 0){
                                    arrayIncremnt += 1;
                                    return (<li data-target={"#carouselExampleIndicators-"+(props.index+1)} data-slide-to={arrayIncremnt}></li>)
                                }
                            })}
                        </ol>
                    }
                    {brands.length && 
                        <div className="carousel-inner">
                            {setSliderData()}
                        </div>
                    }
                 
                </div>
            </div>
            }
        </section>
    )

    
}

export default Brands