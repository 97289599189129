import {APIURL,APIKEY} from '../config'
import {isAuthenticated} from './Auth'
export const getCategories = ()=>{
    return fetch(`${APIURL}/front/category`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        console.log(response)
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getBrands = ()=>{
    //front/brand
    return fetch(`${APIURL}/front/brand`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getSlider = ()=>{
    return fetch (`${APIURL}/front/slider/`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const latestProducts = ()=>{//latestProducts
    return fetch(`${APIURL}/front/products/last`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getproductsCategory = (categoryId)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    console.log(`${APIURL}/front/products/category/${categoryId}`)
    return fetch(`${APIURL}/front/products/category/${categoryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getSingleCategory = (categoryId)=>{ //api/front/category
    return fetch(`${APIURL}/front/category/${categoryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getproductsCategorySort = (categoryId,skip,limit,sort)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    return fetch(`${APIURL}/front/products/classifications/${categoryId}?skip=${skip}&limit=${limit}&sort=${sort}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getproductsCategoryCount = (categoryId)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    return fetch(`${APIURL}/front/products/count/${categoryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getproductsSearchSort = (categoryId,skip,limit,sort)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    return fetch(`${APIURL}/front/products/search/${categoryId}?skip=${skip}&limit=${limit}&sort=${sort}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getproductsSearchCount = (categoryId)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    return fetch(`${APIURL}/front/products/searchcount/${categoryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}


export const getproductsBrandSort = (categoryId,skip,limit,sort)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    return fetch(`${APIURL}/front/products/brand/${categoryId}?skip=${skip}&limit=${limit}&sort=${sort}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getproductsBrandCount = (categoryId)=>{ //front/products/category/5f5c26ad0c52070ad0e71c93
    return fetch(`${APIURL}/front/products/brandcount/${categoryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}


export const getsingleProduct = (productId)=>{ //front/products/single/5f651376fe456319ba98c3d9
    console.log(`${APIURL}/front/products/single/${productId}`)
    return fetch(`${APIURL}/front/products/single/${productId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getSingleProductRatting = (productId)=>{
    return fetch(`${APIURL}/front/comments/ratting/${productId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getSingleProductComments = (productId)=>{
    return fetch(`${APIURL}/front/comments/${productId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getSingleProductRelatedProducts = (categoryId,productId)=>{
    return fetch(`${APIURL}/front/products/related/${categoryId}/${productId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const addSingleProductComment = (productId,comment)=>{
    return fetch(`${APIURL}/front/comments/${productId}`,{
        method:'POST',
        headers:{
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(comment)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const getCountries = ()=>{//api/front/countries
    return fetch(`${APIURL}/front/countries`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const getSingleCountries = (countryId)=>{//api/front/countries
    return fetch(`${APIURL}/front/countries/${countryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const getShipping = ()=>{//shipping
    return fetch(`${APIURL}/front/shipping`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const getPayments = ()=>{
    return fetch(`${APIURL}/front/paymentmethods`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const PlaceNewCart = ()=>{///front/cart/addNewCart
    return fetch(`${APIURL}/front/cart/addNewCart`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        //body:JSON.stringify(cartItem)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const PLaceCart = (cartItem)=>{ //http://localhost:8000/api/front/cart/addtocart
    return fetch(`${APIURL}/front/cart/addtocart`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(cartItem)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const PlaceOrder = (order)=>{//front/order/placeorder
    return fetch(`${APIURL}/front/order/placeorder`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(order)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const getOrders = ()=>{
    return fetch(`${APIURL}/front/order`,{
        method:'GET',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        //body:JSON.stringify(cartItem)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const singleOrder = (orderId)=>{
    return fetch(`${APIURL}/front/order/${orderId}`,{
        method:'GET',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        //body:JSON.stringify(cartItem)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const updateOrder = (order)=>{
    const fd = new FormData();
    
    fd.append('payment_type',order.payment_type)
    fd.append('order_transfer',order.order_transfer)
    fd.append('details',order.details)
    // if(payment.store_code)
    //     fd.append('store_code',payment.store_code[0])

    return fetch(`${APIURL}/front/orders/${order.id}`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
        },
        body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const signIn = (user)=>{ //front/signin
    return fetch(`${APIURL}/front/signin`,{
        method:'POST',
        headers:{
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const signUp = (user)=>{ //front/signup
    return fetch(`${APIURL}/front/signup`,{
        method:'POST',
        headers:{
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const getCustomerInfo = ()=>{
    return fetch(`${APIURL}/front/customer/getinfo`,{
        method:'GET',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        //body:JSON.stringify(cartItem)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const updateCustomerInfo = (customer)=>{
    return fetch(`${APIURL}/front/customer/updateinfo`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(customer)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}


export const generalConfigrations = ()=>{
    return fetch (`${APIURL}/front/configrations`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const designConfigrations = ()=>{
    return fetch (`${APIURL}/front/designconfigrations`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
export const getStaticPages = ()=>{
    return fetch (`${APIURL}/front/page`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getSinglePage = (pageId)=>{
    return fetch (`${APIURL}/front/page/${pageId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const getProductCategoryLimit = (categoryId)=>{
    return fetch(`${APIURL}/front/products/last/${categoryId}`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}
//front/products/lastspecial
export const getProductSpecial = (product)=>{
    return fetch(`${APIURL}/front/products/lastspecial`,{
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            apikey:APIKEY
        },
        body:JSON.stringify(product)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        console.log(error)
    })
}

export const forgetpassword = (email)=>{
    ///front/customer/forgetpassword
    return fetch(`${APIURL}/front/customer/forgetpassword`,{
        method:'POST',
        headers:{
            //Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(email)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const tokenValidation = ()=>{
    ///front/customer/tokenValidation

    return fetch(`${APIURL}/front/customer/tokenValidation`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        //body:JSON.stringify(email)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    // .then((jsonResponse)=>{
    //     console.log(jsonResponse) // here we can catch the response
    // })
    .catch((error)=>{
        //console.log(error)
    })
}

export const checkPayment = (id)=>{
    ///front/orders/checkpayment
    return fetch(`${APIURL}/front/orders/user/checkpayment`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(id)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    // .then((jsonResponse)=>{
    //     console.log(jsonResponse) // here we can catch the response
    // })
    .catch((error)=>{
        //console.log(error)
    })
}

export const checkProducts = (product)=>{
    ///front/products/validateProducts
    return fetch(`${APIURL}/front/products/validateProducts`,{
        method:'POST',
        headers:{
            //Authorization:`Bearer ${isAuthenticated().token}`,
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(product)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    // .then((jsonResponse)=>{
    //     console.log(jsonResponse) // here we can catch the response
    // })
    .catch((error)=>{
        //console.log(error)
    })
}

export const loadCoupon = (coupon)=>{
    ///front/coupons
    return fetch(`${APIURL}/front/coupons`,{
        method:'POST',
        headers:{
            apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(coupon)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        if(response.status === 404){
            return {error:1,"message":"Coupon Not Found"}
        }
        else{
            return response.json()
        }
        
    })
    // .then((jsonResponse)=>{
    //     console.log(jsonResponse) // here we can catch the response
    // })
    .catch((error)=>{
        //console.log(error)
    })
}