/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {Helmet} from "react-helmet";
import {tokenValidation} from  '../api/apiCore'
import {isAuthenticated,signout} from '../api/Auth'

import './assets/vendor_assets/css/bootstrap/bootstrap.css'
import './assets/vendor_assets/css/animate.css'
import './assets/vendor_assets/css/brands.css'
import './assets/vendor_assets/css/fontawesome.min.css'
import './assets/vendor_assets/css/fontello.css'
import './assets/vendor_assets/css/jquery-ui.css'
import './assets/vendor_assets/css/jquery.mb.YTPlayer.min.css'
import './assets/vendor_assets/css/line-awesome.min.css'
import './assets/vendor_assets/css/lnr-icon.css'
import './assets/vendor_assets/css/magnific-popup.css'
import './assets/vendor_assets/css/navigation.css'
import './assets/vendor_assets/css/owl.carousel.min.css'
import './assets/vendor_assets/css/select2.min.css'
import './assets/vendor_assets/css/settings.css'
import './assets/vendor_assets/css/slick.css'
import './assets/vendor_assets/css/trumbowyg.min.css'
import './assets/style.css'
import './assets/style-rtl.css'
import Footer from './components/Footer';
import Header from './components/Header';

import {Card,Button} from 'react-bootstrap'
import {generalConfigrations,getStaticPages} from '../api/apiCore';
import {UPLOADURL} from '../config'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";


  
const Layout = ({title='Title',description = 'Description',className,children})=>{

    useEffect(()=>{
        if(isAuthenticated() !== false){
            tokenValidation().then((data)=>{
                if(data){
                    if("error" in data){
                        signout(()=>{
                            return <Redirect to="/" />
                        })
                    }
                }
            })
        }
    },[])




    const [siteConfigrations,setSiteConfigrations] = useState({})
    const [staticPages,setStaticPages]             = useState([])
    const loadSiteConfigrations = ()=>{
        generalConfigrations().then((data)=>{
            setSiteConfigrations(data)

            if("shop_status" in data){
                if(data.shop_status === false){
                    return <Redirect to="/error"/>
                }
            }
        })
    }

    const loadStaticPages = ()=>{
        getStaticPages().then((data)=>{
            setStaticPages(data)
        })
    }

    useEffect(()=>{
        loadSiteConfigrations()
        loadStaticPages()
    },[])
    return (
        <React.Fragment key="fragment1">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={siteConfigrations.shop_discription} />
                <meta name="description" content={description} />
                <link rel="icon" type="image/jpg" href={UPLOADURL + "/" + siteConfigrations.shop_logo} sizes="16x16" />
                <title>{siteConfigrations.shop_title + " -- " +  title}</title> 

            </Helmet>
            
            <Header data={siteConfigrations} />
            {
                "shop_status" in siteConfigrations && siteConfigrations.shop_status === false && <Redirect to="/error"/>
            }
            {children}
            <Footer data={siteConfigrations} pages={staticPages} />
        </React.Fragment>
    )
} 

export default Layout