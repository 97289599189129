/* eslint-disable */
import React,{useState,useEffect} from 'react';
import Layout from '../Layout'
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
import {Card,Button,Jumbotron,Container} from 'react-bootstrap'
//Import Home Components


const Notfound = ({match,location,history})=> {


    return (
        <Layout title="الصفحة المطلوبة غير متوفرة" description="الصفحة المطلوبة غير متوفرة" className="container">
         
            <Container className="closingmessage m-bottom-150 m-top-150">
                <Jumbotron fluid>
                    <Container>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 text-center"><i class="las la-store"></i></div>
                        <div className="col-lg-6 col-md-6 col-sm-6"><h1>عفواً</h1>
                        <div>الصفحة المطلوبة غير متوفرة</div>
                        </div>
                    </div>
                    </Container>
                </Jumbotron>
            </Container>

        </Layout>
    );
}
  
export default Notfound