/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {generalConfigrations} from '../../../api/apiCore';

const Currency = ()=>{
    const [curruncy_symbole,set_curruncy_symbole] = useState("")
    useEffect(()=>{
        generalConfigrations().then((data)=>{
            if(Object.keys(data).length > 0){
                if('curruncy_symbole' in data){
                    set_curruncy_symbole(data.curruncy_symbole)
                }
            }    
        })
    },[])
    return (
        curruncy_symbole
    )
}

export default Currency