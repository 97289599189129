/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
const TextAds = (props)=>{
    return (
        <section>
            <div className="container">
                <div className="row">
                  
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i class="las la-shipping-fast"></i></span>
                            <h6><a href={props.data.content.link1}>{props.data.content.title1} </a></h6>
                            <p>{props.data.content.text1}</p>
                        </div>
                    </div>
                   

                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i class="las la-shopping-cart"></i></span>
                            <h6><a href={props.data.content.link2}>{props.data.content.title2}</a></h6>
                            <p>{props.data.content.text2}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i class="las la-handshake"></i></span>
                            <h6><a href={props.data.content.link3}>{props.data.content.title3}</a></h6>
                            <p>{props.data.content.text3}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="icon-box-nine text-center">
                            <span className="icon-rounded-sm"><i class="las la-credit-card"></i></span>
                            <h6><a href={props.data.content.link4}>{props.data.content.title4}</a></h6>
                            <p>{props.data.content.text4}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TextAds