/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getCategories} from '../../api/apiCore'
import MenuCol from './InnerCommponets/MenuCol'
import {Link,withRouter} from 'react-router-dom'
import {isAuthenticated,signout} from '../../api/Auth'
import {CartItems,RemoveCartItem,CardTotal} from '../../api/Cart'
import {UPLOADURL} from '../../config'

import {Button,Modal} from 'react-bootstrap';
import Currency from './InnerCommponets/Currency'
//** Categories */
/** Products */
/** loginData */
const Header  = ({history,data})=>{
    const [categories,setCategories] = useState([]) 
    const [error,setError]           = useState(false)
    const [isLoading,setLoading]     = useState(false)

    const loadCategories = ()=>{
        setLoading(true)
        getCategories().then((data)=>{
            if(data.error){
                setError(data.error)
            }
            else{
                setCategories(data)
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        loadCategories()
    },[])

    const [visible,setVisible] = useState('none')

    const hideShowDiv = ()=>{
        visible === 'none' ? setVisible('block') : setVisible('none')
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [search_text,set_search_text] = useState("")
    const searchClick = ()=>{
        console.log(search_text)
        
        if(search_text.trim() !== ""){
            handleClose()
            history.push(`/searchResult/${search_text}`)
        }
    }
//http://localhost:8000/uploads/2fdq8r.png
    return (
        <section className="header header--2">
            <div className="top_bar top--bar2 d-flex align-items-center bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex topbar_content justify-content-between">
                        
                                 <div className="overlay active" style={{display:visible}} onClick={()=>hideShowDiv()}></div>
                                <div className="top_bar--info order-0 d-none d-lg-block align-self-center supportinfo">
                                    <ul>
                                        {data.shop_email !== "" &&
                                        <li><span className="la la-envelope"></span> <p>{data.shop_email}</p></li>
                                        }
                                        {data.shop_phone !== "" &&
                                        <li><span className="la la-headphones"></span> <p>{data.shop_phone}</p></li>
                                        }
                                    </ul>
                                </div>
                                <div className="top_bar--social">
                                    <ul>
                                        {data.facebook_link !== "" && <li><a href={data.facebook_link} target="_blank"><span className="fab fa-facebook-f"></span></a></li> }
                                        {data.twiter_link !== "" && <li><a href={data.twiter_link} target="_blank"><span className="fab fa-twitter"></span></a></li> }
                                        {data.instgram_link !== "" && <li><a href={data.instgram_link} target="_blank"><span className="fab fa-instagram"></span></a></li> }
                                        {data.snapchat_link !== "" && <li><a href={data.snapchat_link} target="_blank"><span className="fab fa-snapchat"></span></a></li> }
                                        {data.youtube_link !== "" && <li><a href={data.youtube_link} target="_blank"><span className="fab fa-youtube"></span></a></li> }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu_area menu1 menu--sticky">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light px-0">
                    <Link className="navbar-brand order-sm-1 order-1 mr-0" to="/"><img width={100} src={UPLOADURL + "/" + data.shop_logo} alt="" /></Link>
                      
                    <button onClick={()=>hideShowDiv()} className="navbar-toggler" type="button" data-toggle="collapseX" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="la la-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse order-md-1" id="navbarSupportedContent2">
                     

                        <ul className="navbar-nav m-1">
                        <li className="nav-item has_mega-lg">
                            <Link className="nav-link" to="/">الرئيسية</Link> 
                            </li>
                            {categories.map((category,i)=>{ 
                                var catChildren = []
                                if(category.children.length > 0 && category.children !== undefined){
                                    var tenItems = []
                                    var item = 
                                    <div className="dropdown-menu">
                                        <ul>
                                        {category.children.map((categoryChild,childIndex)=>{
                                            return (<li key={"innerMenu-"+childIndex} className="dropdown-item text-right">
                                                        <Link to={"/categories/"+categoryChild._id}>{categoryChild.category_title}</Link>
                                                    </li>)
                                        })}
                                        </ul>
                                    </div>
                                    catChildren.push(item)   
                                }
                                if(category.children.length > 0 && category.children !== undefined){
                                var d = <li key={"menu-"+i} className="nav-item dropdown">
                                            <Link className="nav-link" to={"/categories/"+category._id}>{category.category_title}</Link>
                                            {catChildren}
                                        </li>
                                return d     
                                 } else{
                                    var d = <li key={"menu-"+i} className="nav-item">
                                    <Link className="nav-link" to={"/categories/"+category._id}>{category.category_title}</Link>
                                    {catChildren}
                                </li>
                        return d   
                                 }

                             })}
                        </ul>        
                       
                        
                    </div>
                    <div className="nav_right_content d-flex align-items-center order-2 order-sm-2">
                        <div className="nav_right_module cart_module">
                            <div className="cart__icon">
                                <span className="la la-shopping-cart"></span>
                                {CartItems().length !== false && <span className="cart_count">0</span>}
                                {CartItems()  && CartItems().length > 0 && <span className="cart_count">{CartItems().length}</span>}
                            </div>
                            {CartItems().length !== false &&
                            <div className="cart__items shadow-lg-2"><div className="p-4 text-center">سلة المشتريات فارغة</div></div>
                            }

                            {CartItems() && CartItems().length > 0 &&
                                <div className="cart__items shadow-lg-2">
                                    {
                                        CartItems().map((cartItems,index)=>{
                                            return (
                                                <div className="items">
                                                    <div className="item_thumb ml-1">
                                                        {cartItems.productImages.length > 0 &&
                                                            <img width={60} src={UPLOADURL+"/"+cartItems.productImages[0].product_image} />
                                                        }
                                                        {cartItems.productImages.length === 0 &&
                                                            <img src="http://placehold.it/60x60" alt="" />
                                                        }
                                                        
                                                    </div>
                                                    <div className="item_info">
                                                        <Link to={"/product/"+cartItems._id}>{cartItems.product_title}</Link>
                                                        <span className="color-primary mr-2"> {cartItems.product_price} <Currency/> </span>
                                                    </div>
                                                    <a onClick={()=>RemoveCartItem(index,()=>{history.push('/')})} className="item_remove">
                                                        <span className="la la-close"></span>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                          
                                    <div className="cart_info text-md-right">
                                        <p>الإجمالي: <span className="color-primary">{CardTotal().toFixed(2)} <Currency/></span></p>
                                        <Link className="btn btn-outline-secondary btn-sm mt-1" to="/viewcart">سلة المشتريات</Link>
                                        <Link className="btn btn-primary btn-sm mt-1" to="/checkout">تنفيذ الطلب</Link>
                                    </div>
                                </div>
                            }
                            
                        </div>
                        <div className="nav_right_module cart_module d-none d-lg-block">
                            <div className="cart__icon">
                                <span className="la la-user-circle"></span>
                            </div>
                            <div className="cart__items shadow-lg-2" style={{minWidth:250}}>
                                {isAuthenticated() === false && 
                                    <React.Fragment>
                                        <div className="user-header">
                                          
                                            <div>
                                                <i className="la la-user-circle"></i>
                                            </div>
                                            <div className="user-login">
                                                <Link to="/signin" className="btn btn-secondary btn-sm"> الدخول</Link>
                                                <div className="mt-3">
                                                <Link to="/signup">انشاء حساب جديد</Link>
                                                </div>
                                            </div>


                                        </div>
                                        
                                    </React.Fragment>
                                }
                                {isAuthenticated() && 
                                    <React.Fragment>

                                        <div className="user-header">
                                          
                                            <div>
                                                <i className="la la-user-circle"></i>
                                            </div>
                                            <div className="user-login">
                                                
                                                 {isAuthenticated().customer.name}
                                            </div>

                                        </div>

                                        
                                             <div>    
                                                <div class="dropdown-item text-right">
                                                     <Link to="/dashboard"> صفحة حسابي</Link>
                                                </div>
                                                <div class="dropdown-item text-right">
                                                     <Link to="/profile"> تعديل بياناتي</Link>
                                                </div>
                                                <div class="dropdown-item text-right">
                                                     <Link to="/orders">قائمة طلباتي</Link>
                                                </div>
                                                <div class="dropdown-item text-right">
                                                     <Link to="/wishlist">قائمة أمنياتي</Link>
                                                </div>
                                                <div class="dropdown-item text-right">
                                                     <Link to="/address">تعديل عنواني</Link>
                                                </div>
                                                <div class="dropdown-item text-right">
                                                     <Link to="/password">كلمة المرور</Link>
                                                </div>
                                            </div>

                                            <div className="items out">
                                                        <span onClick={()=>signout(()=>{
                                                            history.push('/')
                                                        })} style={{cursor:'pointer'}}>تسجيل الخروج</span>
                                           </div>
                                         
                                      
                                    </React.Fragment>
                                }
                                
                            </div>
                        </div>



                        <div className="nav_right_module search_module">
                            <span className="la la-search search_trigger" onClick={handleShow}></span>

                            <Modal show={show} onHide={handleClose}>
                            <div className="search_area">
                                <form action="/">
                                    <div className="input-group input-group-light p-3">
                                        <span className="icon-left">
                                            <button type="button" onClick={()=>searchClick()} class="btn btn-secondary"><i className="la la-search"></i> ابحث</button>
                                        </span>
                                        <input type="text" onChange={(e)=>set_search_text(e.target.value)} className="form-control search_field" placeholder="أكتب كلمات البحث هنا..." />
                                    </div>
                                </form>
                            </div>
                            </Modal>

                        </div>
                    </div>
                               
                </nav>
            </div>
        </div>



        <div className="wrapper">
   
    <nav id="menusidebar" style={{display:visible}}>

        <div id="dismiss" onClick={()=>hideShowDiv()}>
        <i className="las la-times"></i>
        </div>

{isAuthenticated() === false && 
    <React.Fragment>
        <div className="sidebar-header">
            <div>
            <i className="la la-user-circle"></i>
            </div>
            <div className="user-login">
            
            <Link to="/signin" className="btn btn-secondary btn-sm"> الدخول</Link>
                                                <div className="mt-3">
                                                <Link to="/signup">انشاء حساب جديد</Link>
                                                </div>



            </div>
   
        </div>
     </React.Fragment>
 }
{isAuthenticated() && 
    <React.Fragment>

<div className="sidebar-header">
        <div>
            <i className="la la-user-circle"></i>
        </div>
        <div className="user-login">
        {isAuthenticated().customer.name}
        </div>
 </div>

    </React.Fragment>
}

        <ul className="list-unstyled components">
        <li>
                <Link to="/">الرئيسية</Link>
            </li>
            <li>
                <Link to="/dashboard">صفحة حسابي</Link>
                <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="menu_arrow"><i className="la la-angle-down"></i></a>
                <ul className="collapse list-unstyled" id="homeSubmenu">
                   
                    <li>
                        <Link to="/profile"> تعديل بياناتي</Link>
                    </li>
                    <li>
                        <Link to="/orders">قائمة طلباتي</Link>
                    </li>
                    <li>
                        <Link to="/wishlist">قائمة أمنياتي</Link>
                    </li>
                    <li>
                        <Link to="/address">تعديل عنواني</Link>
                    </li>
                    <li>
                        <Link to="/password">كلمة المرور</Link>
                    </li>
                    <li>
                    <Link onClick={()=>signout(()=>{history.push('/')})} style={{cursor:'pointer'}}>تسجيل الخروج</Link>
                    </li>
                </ul>
            </li>
      
            <li className="p-3">
            أقسام المتجر
            </li>
{categories.map((category,i)=>{
    let catData = []
    
    if(category.children.length > 0 && category.children){
        category.children.map((catD,catDIndex)=>{
            catData.push(
                <li>
                    <Link to={"/categories/"+catD._id}>{catD.category_title}</Link>
                </li>
            )
        })
    }
    return (
        <li>
            <Link to={"/categories/"+category._id}>{category.category_title}</Link>
            {category.children.length > 0 && category.children !== undefined &&
                <a href={"#pageSubmenu"+category._id} data-toggle="collapse" aria-expanded="false" className="menu_arrow"><i className="la la-angle-down"></i></a>
            }
            {category.children.length > 0 && category.children !== undefined &&  <ul className="collapse list-unstyled" id={"pageSubmenu"+category._id}>
                    {catData}
                </ul>}
        </li>        
    )
})}
            {/* {categories.map((category,i)=>{ 
             var catChildren = []
             if(category.children.length > 0 && category.children !== undefined){
             var tenItems = []
              var item = 
               <div className="mega-menu mega-menu-lg d-lg-flex flex-wrap">
                     <ul>
                        {category.children.map((categoryChild,childIndex)=>{
                        return (<li key={"innerMenu-"+childIndex}>
                        <Link to={"/categories/"+categoryChild._id}>{categoryChild.category_title}</Link>
                                </li>)
                         })}
                     </ul>
                 </div>
                catChildren.push(item)   
                }
                                
                 var d = <li key={"menu-"+i} className="nav-item has_mega-lg dropdown">
                            <Link className="nav-link" to={"/categories/"+category._id}>{category.category_title}</Link>
                             {catChildren}
                          </li>
                      return d        
                             })} */}




        </ul>
    </nav>

 
 
  
   
</div>


        </section>





    )
}

export default withRouter(Header)