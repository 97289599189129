/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated,signout} from '../../api/Auth'
import {Card} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {Toast} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {getCustomerInfo,updateCustomerInfo} from '../../api/apiCore'
const Password = ({history})=>{
    const[password,setPassword] = useState("")
    const[confirmPassword,setConfirmPassword] = useState("")
    const [show, setShow] = useState(false);
    const [passwordMessage,set_passwordMessage] = useState("")

    const saveChanges = ()=>{

        if(password  === confirmPassword && password.length > 8){
            updateCustomerInfo({password:password}).then((data)=>{
                setShow(true)
            })
            set_passwordMessage("")

        } else if(password  !== confirmPassword){
            set_passwordMessage(<div className="alert alert-danger">عفواً، كلمة المرور غير متطابقة في حقل التأكيد</div>)

        } else if(password.length < 8){
            set_passwordMessage(<div className="alert alert-danger">عفوأ، كلمة المرور فارغة أو أقل من 8 خانات</div>)
        }
        
    }

    
    useEffect(()=>{
        if(isAuthenticated() === false)
            history.push('/')
    },[])
    

    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">حسابي</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">الرئيسية</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">كلمة المرور</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-0 order-md-0 mb-4">
                                <div className="sidebar">
                                  
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">القائمة</h6>
                                            </div>
                                            <div className="widget-content pr-0 pl-0">
                                                <div className="category-widget">
                                                    <div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/dashboard">صفحة حسابي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/profile">تعديل بياناتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/orders">قائمة طلباتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/wishlist">قائمة أمنياتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/address">تعديل عنواني</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/password">كلمة المرور</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link onClick={()=>signout(()=>{history.push('/')})} style={{cursor:'pointer'}}>تسجيل الخروج</Link></div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                               
                            
                          
                                <div className="row">
                                    <div className="col-lg-12 col-md-11 mb-4">
                                        <div className="sidebar">
                                        
                                            <div className="widget-wrapper">
                                                <div className="widget-default">
                                                    <div className="widget-header">
                                                        <h6 className="widget-title">تعديل كلمة المرور</h6>
                                                    </div>
                                                    <div className="widget-content">
                                                        <div className="category-widget">
                                                            
                                                            {passwordMessage}
                                                           
                                                                <form className="was-validated">
                                                         
                                                                    <div className="form-group">
                                                                    <Form.Label>كلمة المرور الجديدة</Form.Label>
                                                                        <input onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="كلمة المرور الجديدة" className="form-control" required/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                    <Form.Label> تأكيد كلمة المرور الجديدة</Form.Label>
                                                                        <input onChange={(e)=>setConfirmPassword(e.target.value)} type="password" placeholder="تأكيد كلمة المرور الجديدة" className="form-control" required/>
                                                                    </div>
                                                                   
                                                                   
                                                                    <div className="form-group text-center m-bottom-20">
                                                                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                                                        <Toast.Body className="text-success">تم حفظ التعديلات بنجاح!</Toast.Body>
                                                                    </Toast>

                                                                            <button  onClick={()=>saveChanges()} className="btn btn-secondary" type="button">حفظ التعديل</button>
                                                                    </div>
                                                                </form>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                            </div>

                          


                        </div>
                    </div>
                </section>

        </Layout>
    )    
}
export default Password;