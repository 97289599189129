/* eslint-disable */
import React,{useState,useEffect} from 'react';
import {getBrands,getSlider,latestProducts,designConfigrations,generalConfigrations} from '../../api/apiCore';
import Layout from '../Layout'
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
//Import Home Components

import TextAds from './HomeCommponets/TextAds'
import ImageAds from './HomeCommponets/ImageAds'
import Banner from './HomeCommponets/Banner'
import Brands from './HomeCommponets/Brands'
import Slider from './HomeCommponets/Slider'
import Fixed from './HomeCommponets/Fixed'
import Grid  from './HomeCommponets/Grid'
import SliderSpecial from './HomeCommponets/SliderSpecial'
import FixedSpecial from './HomeCommponets/FixedSpecial'
import GridSpecial  from './HomeCommponets/GridSpecial'
const Home = ({match,location,history})=> {
    const [brands,getStateBrands] = useState([])
    const [slider,getStateSlider] = useState([])
    const [products,getStateProducts] = useState([])
    const [error,setError] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const loadBrands = ()=>{
        getBrands().then((data)=>{
            if(data.error)
                console.log(data.error)
            else{
                console.log(data)
                getStateBrands(data)
            }
                    
        })
    }
    
    const loadSlider = ()=>{
        getSlider().then((data)=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                console.log(data)
                getStateSlider(data)
            }
        })
    }

    const loadLastProducts = ()=>{
        latestProducts().then((data)=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                console.log(data)
                getStateProducts(data)
            }
        })
    }
    
    const [frontDesignConfigrations,setFrontConfigrations] = useState([])
    
    const loadDesignConfigrations = ()=>{
        designConfigrations().then((data)=>{
            console.log(data)
            setFrontConfigrations(data)
        })
    }
    const loadGeneralConfigrations = ()=>{

    }

    useEffect(()=>{
        loadBrands()
        loadSlider()
        loadLastProducts()
        loadDesignConfigrations()
    },[])
///front/products/lastspecial
    return (
        <Layout title="الرئيسية" description="الصفحة الرئيسية" className="container">
            {
                frontDesignConfigrations.map((component,index)=>{
                    if(component.type === "TextBlock")
                    {
                        return (<TextAds data={component} index={index} />)
                    }
                    else if(component.type === "ImageBlock")
                    {
                        return (<ImageAds data={component} index={index} />)
                    }
                    else if(component.type === "Banners")
                    {
                        return (<Banner data={component} index={index} />)
                    }
                    else if(component.type === "Brands"){
                        return (<Brands data={component} index={index} />)
                    }
                    else if(component.type === "slider"){
                        return (<Slider data={component} history={history} index={index} />)
                    }
                    else if(component.type === "fixed"){
                        return (<Fixed data={component} history={history} index={index} />)
                    }
                    else if(component.type === "grid"){
                        return (<Grid data={component} history={history} index={index} />)
                    }

                    else if(component.type === "mostview" || component.type === "mostrate" || component.type === "mostsell" || component.type === "mostoffer"){
                        if("display" in component.content)
                        {
                            if(component.content.display === "slider"){
                                return (<SliderSpecial data={component} history={history} index={index} />)
                            }
                            else if(component.content.display === "fixed"){
                                return (<FixedSpecial data={component} history={history} index={index} />)
                            }
                            else if(component.content.display === "grid"){
                                return (<GridSpecial data={component} history={history} index={index} />)
                            }
                        }
                    }
                    
                })
            }
        </Layout>
    );
}
  
export default Home