/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {getsingleProduct,getSingleCategory,getSingleProductRelatedProducts,getSingleProductComments,getSingleProductRatting,addSingleProductComment} from '../../api/apiCore'
import Layout from '../Layout'
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
import {Carousel,Form,Col,Row,ButtonGroup,Button,ListGroup} from 'react-bootstrap';
import {
    atan2, chain, derivative, e, evaluate, log, pi, pow, round, sqrt,abs
  } from 'mathjs'
import {
    FacebookShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    HatenaShareCount,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon,
} from "react-share";
import {Toast} from 'react-bootstrap'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';  
import {AddCartItem} from '../../api/Cart'
import {AddFavoritItem} from '../../api/Favorites'
import Currency from './InnerCommponets/Currency'
import { isAuthenticated } from '../../api/Auth'
const Product = ({match,location,history})=>{
    const productId                              = match.params.productId
    const [currentProduct,getCurrentProduct]     = useState({})
    const [currentProductId,setCurrentProductId] = useState(productId)
    const [productPrice,setProductPrice] = useState(0)
    const [category,setCategory] = useState({})

    const [relatedProducts,setRelatedProducts] = useState([])
    const [productComments,setProductComments] = useState([])
    const [productRatting,setProductRatting] = useState([])
    const [fixedShow,setFixedShow] = useState(false)
    if(productId !== currentProductId)
        setCurrentProductId(productId)

    const loadCurrentProduct = ()=>{
        getsingleProduct(productId).then((data)=>{
            getCurrentProduct(data)
            if(data.product_discount_price){
                setProductPrice(data.product_discount_price)
            }
            else{
                setProductPrice(data.product_price)
            }
            //console.log(data)
            getSingleCategory(data.category).then((categoryData)=>{
                setCategory(categoryData)
            })  
            //relatedProducts
            getSingleProductRelatedProducts(data.category,productId).then((related)=>{
                setRelatedProducts(related)
            })
            //ProductComments
            getSingleProductComments(productId).then((comments)=>{
                setProductComments(comments)
            })
            //Product Ratting
            getSingleProductRatting(productId).then((ratting)=>{
                
                if(ratting.length > 0){
                    ratting[0].rate=ratting[0].total/ratting[0].count 
                    // console.log (ratting)
                    ratting[0].rate = Math.trunc(ratting[0].rate)
                    setProductRatting(ratting[0])
                }
                    
                else
                    setProductRatting({
                    "total": 0,
                    "rate": 0,
                    "count": 0})    
            })

            // check if product has stock
            if(data.product_quantity === 0){
                history.push('/')
            }
            //Check Product Attribute
            if('productAttributes' in data){
                //check if there is empty attribute
                if(data.productAttributes.length > 0){
                    for(var i=0;i<data.productAttributes.length;i++){
                        var checkQ = 0;
                        for(var x=0;x<data.productAttributes[i].attributeValues.length;x++){
                            checkQ += data.productAttributes[i].attributeValues[x].attribute_value_quantity
                        }
                        if(checkQ === 0){
                            history.push('/')
                        }
                    }
                    console.log(data)
                }
            }
        })
    }    

    useEffect(()=>{
        loadCurrentProduct()
        window.scrollTo(0, 0)
    },[productId])
    const shareUrl = window.location.href;
    const title = currentProduct.product_title;
    const attributeClick = (attribute,attributeValue)=>{
        const newArray = Object.assign({},currentProduct)
        var newPrice = productPrice
        for(var i=0;i<newArray.productAttributes.length;i++){
            if(newArray.productAttributes[i]._id === attribute._id){
                for(var x=0;x<newArray.productAttributes[i].attributeValues.length;x++){
                    if(newArray.productAttributes[i].attributeValues[x]._id === attributeValue._id){
                        
                        if(newArray.productAttributes[i].attributeValues[x].select === true){
                            newArray.productAttributes[i].attributeValues[x].select = false
                            //setProductPrice(productPrice - newArray.productAttributes[i].attributeValues[x].attribute_value_extra_price)
                            newPrice -= newArray.productAttributes[i].attributeValues[x].attribute_value_extra_price
                        }   
                        else{
                            newArray.productAttributes[i].attributeValues[x].select = true
                            //setProductPrice(productPrice + newArray.productAttributes[i].attributeValues[x].attribute_value_extra_price)
                            newPrice += newArray.productAttributes[i].attributeValues[x].attribute_value_extra_price
                            
                            newArray.productAttributes[i].chooise = true
                        }
                    }
                    //if(newArray.productAttributes[i].attributeValues[x]._id !== attributeValue._id){
                    else{
                        if(newArray.productAttributes[i].attributeValues[x].select === true){
                            newArray.productAttributes[i].attributeValues[x].select = false
                            //setProductPrice(productPrice - newArray.productAttributes[i].attributeValues[x].attribute_value_extra_price)
                            newPrice -= newArray.productAttributes[i].attributeValues[x].attribute_value_extra_price
                        }
                        newArray.productAttributes[i].attributeValues[x].select = false 
                    }
                }
                break;
            }
        }
        setProductPrice(newPrice)
        getCurrentProduct(newArray)
        console.log(currentProduct)
    }

    const [Addcustomcss3,SetAddCustomCss3]=useState("btn like-btn btn-grey")
    const [Addcustomcss4,SetAddCustomCss4]=useState("btn like-btn btn-grey animated bounce")
    //Add To Favorit
    const AddItemToFavorit = ()=>{
        AddFavoritItem(currentProduct,()=>{
            history.push('/product/'+currentProduct._id)
        })
        SetAddCustomCss3(Addcustomcss4)
    }
    const [Addcustomcss1,SetAddCustomCss1]=useState("btn btn--rounded btn-primary ml-3")
    const [Addcustomcss2,SetAddCustomCss2]=useState("btn btn--rounded btn-primary ml-3 animated bounce")

    const AddItemToCard = ()=>{
        //Befor we add the item to card we need to check we have atlest checked option from every attribute
        const newArray = Object.assign({},currentProduct)
        var trueCount = 0
        for(var i=0;i<newArray.productAttributes.length;i++){
            var currentAttributeStatus = false
            for(var x=0;x<newArray.productAttributes[i].attributeValues.length;x++){
                if(newArray.productAttributes[i].attributeValues[x].select === true){
                    currentAttributeStatus = true
                    trueCount += 1
                }
            }
            if(currentAttributeStatus === false){
                newArray.productAttributes[i].chooise = false
            }
        }
        getCurrentProduct(newArray)
        if(trueCount === newArray.productAttributes.length){
            AddCartItem(currentProduct,()=>{
                history.push('/product/'+currentProduct._id)
            })
        SetAddCustomCss1(Addcustomcss2)
        }
        
    }
    const [commentName,setCommentName] = useState(isAuthenticated() ? isAuthenticated().customer.name : '')
    const [commentEmail,setCommentEmail] = useState(isAuthenticated() ? isAuthenticated().customer.email : '')
    const [commentRatting,setCommentRatting] = useState()
    const [commentText,setCommentText] = useState("")
    const [commentMessage,setCommentMessage] = useState("")
    const sentComment = ()=>{
        if(commentName.length < 3){
            setCommentMessage(<div className="alert alert-danger"> الاسم يجب الا يقل عن 3 أحرف </div>)
        }
        else if(commentText.length < 4){
            setCommentMessage(<div className="alert alert-danger"> التعليق يجب الا يقل عن 4 أحرف </div>)
        }
        else if(!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(commentEmail))){
            setCommentMessage(<div className="alert alert-danger"> يرجي اضافة بريد الكتروني صحيح </div>)
        }
        else{
            addSingleProductComment(productId,{name:commentName,email:commentEmail,ratting:commentRatting,comment:commentText}).then((data)=>{
                setCommentMessage(<div className="alert alert-success"> شكراً لك، تم ارسال التعليق بنجاح </div>)
                setCommentName("")
                setCommentEmail("")
                setCommentText("")
            })
        }
        
    }

    return (
        <Layout title={currentProduct.product_seo_title} description={currentProduct.product_seo_description} className="container">
            <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{category.category_title}</li>
                                        <li className="breadcrumb-item active" aria-current="page">{currentProduct.product_title}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-details p-top-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-0 col-md-8 offset-md-2 margin-md-60">
                            <div className="product-gallery">
                            {currentProduct.productImages && 
                                <div className="gallery-image-view">
                                    <div className="image-view-carousel owl-carousel">
                                        {
                                            currentProduct.productImages.map((image,index)=>{
                                                return (
                                                    <div className="carousel-single" data-hash="image1">
                                                        <figure><img src={UPLOADURL + "/" + image.product_image} alt="" /></figure>
                                                    </div>
                                                )
                                            })
                                        }
                                    
                                    </div>
                                   
                                </div>
                            }
                                
                            </div>
                            {currentProduct.productImages && 
                            <Carousel>
                                {
                                    currentProduct.productImages.map((image,index)=>{
                                        return (
                                            <Carousel.Item>
                                                <img className="d-block w-100" src={UPLOADURL + "/" + image.product_image} alt={index}/>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                             </Carousel>
                            }
                            {!currentProduct.productImages &&
                                <img src="http://placehold.it/360x240" className="d-block w-100" alt="holder" />
                            }


                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="product-info">
                                <h4>{currentProduct.product_title}</h4>
                                {/* <span className="product-price">${currentProduct.product_price}</span> */}
                                {currentProduct.product_discount_price &&
                                    <React.Fragment>
                                        <span className="old-price">{currentProduct.product_price} <Currency/></span>
                                        <span className="product-price color-secondary">{productPrice} <Currency/></span>
                                    </React.Fragment>
                                }
                                {currentProduct.product_discount_price === undefined &&
                                    <span className="product-price color-secondary">{productPrice} <Currency/></span>
                                }
                                <div className="product-ratings d-flex">
                                    {
                                        productRatting.rate === 0 &&
                                        <p>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                        </p>
                                    }
                                    {
                                        productRatting.rate === 1 &&
                                        <p>
                                            <span className="fas fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                        </p>
                                    }
                                    {
                                        productRatting.rate === 2 &&
                                        <p>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                        </p>
                                    }
                                    {
                                        productRatting.rate === 3 &&
                                        <p>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="far fa-star"></span>
                                            <span className="far fa-star"></span>
                                        </p>
                                    }
                                    {
                                        productRatting.rate === 4 &&
                                        <p>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="far fa-star"></span>
                                        </p>
                                    }
                                    {
                                        productRatting.rate === 5 &&
                                        <p>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                            <span className="fas fa-star"></span>
                                        </p>
                                    }
                                    <span className="total-reviews mr-1">({productRatting.count} تعليقات)</span>
                                </div>
                                <p>{currentProduct.product_short_description} </p>

                                <div className="product-actions">
                                        {
                                            currentProduct.productAttributes && currentProduct.productAttributes.length > 0 && 
                                            currentProduct.productAttributes.map((attribute,index)=>{
                                                var selectedContainerStyle = {}
                                                if(attribute.chooise === false){
                                                    selectedContainerStyle = {borderColor:"#f00",borderWidth:'2px',borderStyle:'solid',backgroundColor:'#fff'}
                                                }
                                                return (
                                                    
                                                    <Form.Group as={Row} style={selectedContainerStyle}>
                                                        <Form.Label column sm="12" md="12" lg="12">
                                                            <h6><i class="lar la-arrow-alt-circle-left"></i> {attribute.attribute_name}</h6>
                                                        </Form.Label>
                                                        {/* <Col sm="10">
                                                            <Form.Control defaultValue="email@example.com" />
                                                        </Col> */}
                                                        {attribute.attribute_type === "Image" &&
                                                            <div className="attributeContaier container">
                                                                <ul className="attributeListImage row">

                                                                    {
                                                                        attribute.attributeValues.map((at,ind)=>{
                                                                            
                                                                            if(at.attribute_value_quantity === 0)
                                                                                return

                                                                            var selectedStyle = {}
                                                                            if(at.select === true){
                                                                                selectedStyle = {borderColor:"#000"}
                                                                            }
                                                                            
                                                                            return (<li onClick={()=>{attributeClick(attribute,at)}} className="col-lg-3 col-md-2 col-sm-6"><img style={selectedStyle} src={UPLOADURL + "/" + at.attribute_value_name} width={100} /></li>)
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>    
                                                            
                                                        }
                                                        {attribute.attribute_type === "Text" && 
                                                            <div className="attributeContaier container">
                                                                <ul className="attributeListText row">
                                                                    {
                                                                        attribute.attributeValues.map((at,ind)=>{
                                                                            if(at.attribute_value_quantity === 0)
                                                                                return
                                                                            var selectedStyle = {}
                                                                            if(at.select === true){
                                                                                selectedStyle = {borderColor:"#000"}
                                                                            }
                                                                            return (<li style={selectedStyle} onClick={()=>{attributeClick(attribute,at)}} className="col-lg-3 col-md-2 col-sm-6">{at.attribute_value_name}</li>)
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>    
                                                        }
                                                        {attribute.attribute_type === "Color" &&
                                                            <div className="attributeContaier container">
                                                                <ul className="attributeListColor row">
                                                                    {
                                                                        attribute.attributeValues.map((at,ind)=>{

                                                                            if(at.attribute_value_quantity === 0)
                                                                                return

                                                                            var selectedStyle = {}
                                                                            if(at.select === true){
                                                                                selectedStyle = {borderColor:"#000"}
                                                                            }
                                                                            return (<li onClick={()=>{attributeClick(attribute,at)}} className="col-lg-2 col-md-2 col-sm-6"><div style={selectedStyle} className="contcolor"><div className="color" style={{backgroundColor:at.attribute_value_name}}> </div></div></li>)
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>    
                                                        }
                                                        <div style={{clear:"both"}}></div>
                                                    </Form.Group>
                                                )
                                            })
                                        }
                                        
                                        <div className="flex-wrap">
                                            {/* <div className="total-item">
                                                <span className="pcount m-left-10" data-operation="minus">-</span>
                                                <input type="text" value="1" id="numberInput" />
                                                <span className="pcount m-right-10" data-operation="plus">+</span>
                                            </div> */}
                                            <ul className="d-flex">
                                                <li><span onClick={()=>AddItemToCard()} className={Addcustomcss1}>أضف لسلة المشتريات</span></li>
                                                {/* <li><span onClick={()=>AddFavoritItem(currentProduct,()=>{history.push('/product/'+currentProduct._id)})} className="btn like-btn btn-grey"><i className="la la-heart-o"></i></span></li> */}
                                                <li><span onClick={()=>AddItemToFavorit()} className={Addcustomcss3}><i className="la la-heart-o"></i></span></li>
                                            </ul>
                                        </div>
                                    
                                </div>
                                <div className="product-category d-flex m-bottom-25">
                                    <span>القسم: </span>
                                    <p><Link to={"/categories/"+category._id}>{category.category_title}</Link></p>
                                </div>
                                <div className="product-share d-flex align-items-center">
                                    <span className="m-left-20">مشاركة: </span>
                                    <div>
                                        <div className="social social--colored mt-4">
                                            <ul className="d-flex flex-wrap">
                                         

                                                <div className="Demo__some-network">
                                                <FacebookShareButton
                                                    url={shareUrl}
                                                    quote={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>

                                                <div>
                                                    <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                                    {count => count}
                                                    </FacebookShareCount>
                                                </div>
                                                </div>

                                                <div className="Demo__some-network">
                                                <FacebookMessengerShareButton
                                                    url={shareUrl}
                                                    appId="521270401588372"
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookMessengerIcon size={32} round />
                                                </FacebookMessengerShareButton>
                                                </div>

                                                <div className="Demo__some-network">
                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <TwitterIcon size={32} round />
                                                </TwitterShareButton>

                                                <div className="Demo__some-network__share-count">&nbsp;</div>
                                                </div>

                                                <div className="Demo__some-network">
                                                <TelegramShareButton
                                                    url={shareUrl}
                                                    title={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <TelegramIcon size={32} round />
                                                </TelegramShareButton>

                                                <div className="Demo__some-network__share-count">&nbsp;</div>
                                                </div>

                                                <div className="Demo__some-network">
                                                <WhatsappShareButton
                                                    url={shareUrl}
                                                    title={title}
                                                    separator=":: "
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <WhatsappIcon size={32} round />
                                                </WhatsappShareButton>

                                                <div className="Demo__some-network__share-count">&nbsp;</div>
                                                </div>

      
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="hr-divider p-bottom-35"></div>
            <section className="product-info-tab">
                <div className="tab tab--6">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab_nav2">
                                    <ul className="nav justify-content-center" id="tab6" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="tab6_nav1" data-toggle="tab" href="#tab6_content1" role="tab" aria-controls="tab6_content1" aria-selected="true">تفاصيل</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="tab6_nav2" data-toggle="tab" href="#tab6_content2" role="tab" aria-controls="tab6_content2" aria-selected="false">Additional information</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link" id="tab6_nav3" data-toggle="tab" href="#tab6_content3" role="tab" aria-controls="tab6_content3" aria-selected="false"> تعليقات ({productRatting.count})</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab-content tab--content2" id="tabcontent6">
                                    <div className="tab-pane fade show active" id="tab6_content1" role="tabpanel" aria-labelledby="tab6_content1">
                                        <h4>تفاصيل</h4>
                                        <p>{ReactHtmlParser(currentProduct.product_long_description)}</p>
                                    </div>
                                    {/* <div className="tab-pane fade" id="tab6_content2" role="tabpanel" aria-labelledby="tab6_content2">
                                        <h4>Additional Information</h4>
                                        <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius.
                                            Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. Investig
                                            ationes demons trave runt lectores legere liusry awquod he legunt saepius clary tyitas
                                            Investig atesw.
                                            Curabitur vel sagittis lectus. Donec vitae efficitur ante. Nam mollis ligula tortor.
                                            Interdum et malesuada fames.</p>
                                        <ul className="bullet--list1 m-top-30">
                                            <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>
                                            <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>
                                            <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>
                                        </ul>
                                    </div> */}
                                    <div className="tab-pane fade" id="tab6_content3" role="tabpanel" aria-labelledby="tab6_content3">
                                        <h4>تعليقات</h4>
                                        <div className="review-wrapper">
                                            <div className="reviews">
                                                <div className="row">
                                                    {
                                                        productComments.map((comment,index)=>{
                                                            return (
                                                                <div className="col-lg-12">
                                                                    <div className="review-single">
                                                                        <div className="review-details">
                                                                            <ul>
                                                                                <li>
                                                                                    <span>الإسم: </span>
                                                                                    <span className="strong">{comment.name}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span>التاريخ: </span>
                                                                                    <span className="strong">{new Date(comment.createdAt).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span>التقييم: </span>
                                                                                    {
                                                                                        comment.ratting === 0 &&
                                                                                        <span className="strong">
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        comment.ratting === 1 &&
                                                                                        <span className="strong">
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        comment.ratting === 2 &&
                                                                                        <span className="strong">
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        comment.ratting === 3 &&
                                                                                        <span className="strong">
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        comment.ratting === 4 &&
                                                                                        <span className="strong">
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="far fa-star"></span>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        comment.ratting === 5 &&
                                                                                        <span className="strong">
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                            <span className="fas fa-star"></span>
                                                                                        </span>
                                                                                    }
                                                                                    
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="review-text">
                                                                            <p>{comment.comment}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="review-form cardify">

                                             {commentMessage}
                                            
                                                <h4>تعليقك على: </h4>
                                                <h6><a href="">{currentProduct.product_title}</a></h6>
                                                <div>
                                                    
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <div className="rating m-bottom-35 d-flex align-items-center">
                                                                <span>تقييمك: </span>
                                                                <div className="form-group br-wrapper br-theme-fontawesome-stars mr-3">
                                                                    <select className="give_rating form-control" value={commentRatting} onChange={(e)=>setCommentRatting(e.target.value)}>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-3">
                                                            <input type="text" placeholder="الإسم" value={commentName} onChange={(e)=>setCommentName(e.target.value)} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-6 mb-3">
                                                            <input type="email" placeholder="البريد الإلكتروني" value={commentEmail} onChange={(e)=>setCommentEmail(e.target.value)} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-12 mb-3">
                                                            <textarea placeholder="تعليقك" value={commentText} onChange={(e)=>setCommentText(e.target.value)} className="form-control" rows="3"></textarea>
                                                            <button onClick={()=>sentComment()} className="btn btn-primary mt-3">إرسال</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <div className="hr-divider2"></div>
            {
                relatedProducts.length > 0 && 
                <section className="related-products m-bottom-80 p-top-20">
                    <div className="m-bottom-40">
                        <div className="divider divider-simple text-center">
                            <h3>منتجات ذات صلة</h3>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {
                                relatedProducts.map((product,index)=>{
                                    return (
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="card card-product card-img">
                                                <figure>
                                                    {product.productImages.length > 0 && <img src={UPLOADURL + "/" + product.productImages[0].product_image} alt="" />} 
                                                    {product.productImages.length === 0 && <img src="http://placehold.it/360x240" alt="" /> }
                                                    {/* <img src="http://placehold.it/360x240" alt="" /> */}
                                                    <figcaption>
                                                        <ul className="d-flex justify-content-center">
                                                            {product.productAttributes.length === 0 && 
                                                                <li><span onClick={()=>AddCartItem(product,()=>{
                                                                    setFixedShow(true)
                                                                    history.push('/product/'+productId)
                                                                })} className="btn btn--rounded btn-outline-light">أضف للسلة</span></li>
                                                            }

                                                            {product.productAttributes.length > 0 && <li><Link to={"/product/"+product.id} className="btn btn--rounded btn-outline-light btn-sm">تفاصيل المنتج</Link></li>}

                                                            <li><span onClick={()=>AddFavoritItem(product,()=>{history.push('/product/'+productId)})} className={Addcustomcss3}><i className="la la-heart-o"></i></span></li>
                                                        </ul>
                                                    </figcaption>
                                                </figure>
                                                {/* <span className="badge badge-pill badge-primary">-49%</span> */}
                                                <div className="card-body">
                                                    <h6><Link to={"/product/"+product.id}>{product.product_title}</Link></h6>
                                                    <div className="prices">
                                                        {product.product_discount_price &&
                                                            <React.Fragment>
                                                                <span className="old-price">{product.product_price} <Currency/></span>
                                                                <span className="product-price color-secondary">{product.product_discount_price} <Currency/></span>
                                                            </React.Fragment>
                                                        }
                                                        {product.product_discount_price === undefined &&
                                                            <span className="product-price color-secondary">{product.product_price} <Currency/></span>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    )
                                })
                            }   
                    <div>
                        <Toast onClose={() => setFixedShow(false)} show={fixedShow} delay={3000} autohide>
                            <Toast.Body className="addtocart">تم اضافته للسلة بنجاح!</Toast.Body>
                        </Toast>
                    </div>

                        </div>
                        
                    </div>
                   
                </section>
            }
            
        </Layout>
    )
}

export default Product