/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {UPLOADURL} from '../../../config'
import {Link} from 'react-router-dom'
const ImageAds = (props)=>{
    return (
        <section className="shop-features p-top-10 p-bottom-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <a href={props.data.content.link}><img src={UPLOADURL + "/" + props.data.content.image} alt="" /></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageAds