/* eslint-disable */
import React,{useState,useEffect} from 'react'
//Import Banner Data
import {getSlider} from '../../../api/apiCore';
import {UPLOADURL} from '../../../config'
import {Link} from 'react-router-dom'

var arrayIncremnt = 0;
const Banner = (props)=>{
    const [slider,getStateSlider] = useState([])
    const loadSlider = ()=>{
        getSlider().then((data)=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                console.log(data)
                getStateSlider(data)
            }
        })
    }
    useEffect(()=>{
        loadSlider()
    },[])
    return(
        <section className="carousel slide container mt-4 mb-4">
            <div className="row">
                <div className="carousel-inner">
                    <div id={"carouselExampleIndicators-"+(props.index+1)} className="carousel slide" data-ride="carousel">
                    {slider.length > 0 && 
                        <ol className="carousel-indicators">
                            {slider.map((slide,index)=>{
                                if(index == 0){
                                    return (<li data-target={"#carouselExampleIndicators-"+(props.index+1)} className="active" data-slide-to={index}></li>)
                                }
                                else{
                                    arrayIncremnt += 1;
                                    return (<li data-target={"#carouselExampleIndicators-"+(props.index+1)} data-slide-to={index}></li>)
                                }
                            })}
                        </ol>
                    }



                        <div id="specialbanner" className="carousel-inner">

                        <a className="carousel-control-prev" href={"#carouselExampleIndicators-"+(props.index+1)} role="button" data-slide="prev">
                            <span className="las la-angle-left color-primary" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href={"#carouselExampleIndicators-"+(props.index+1)} role="button" data-slide="next">
                            <span className="las la-angle-right color-primary" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>

                        
                            {slider.map((slide,index)=>{
                                if(index === 0){
                                    return (
                                        <div className="carousel-item active" data-interval={(index+1)*10000}>
                                            <a href={slide.slider_link}>
                                                <img src={UPLOADURL + "/" + slide.slider_image} className="d-block w-100" alt="..." />
                                            </a>
                                        </div>
                                    )
                                }
                                else{
                                    return (
                                        <div className="carousel-item" data-interval={(index+1)*10000}>
                                            <a href={slide.slider_link}>
                                                <img src={UPLOADURL + "/" + slide.slider_image} className="d-block w-100" alt="..." />
                                            </a>
                                        </div>
                                    )
                                }
                                
                            })}
                            
                         
                        </div>
                    
                    </div>            
                </div>
            </div>
        </section> 
    )
}

export default Banner