/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated,signout,EditLocalStorage} from '../../api/Auth'
import {Card} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {Toast} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {getCustomerInfo,updateCustomerInfo,getCountries} from '../../api/apiCore'
const Address = ({history})=>{
    const [customerInfo,setCustomerInfo] = useState({})
    const [countries,setCountries]       = useState([])
    const [cities,setCities]             = useState([])
    const loadCustomerInfo = ()=>{
        getCustomerInfo().then((userinfo)=>{
            setCustomerInfo(userinfo)
            setCustomerCountry(userinfo.country)
            setCustomerCity(userinfo.city)
            setCustomerAddress(userinfo.address)

            getCountries().then((data)=>{
                setCountries(data)
                //set city
                data.forEach(element => {
                    if(element._id === userinfo.country){
                        setCities(element.children)
                    }
                });
            })
        })
    }

    const [customerCountry,setCustomerCountry] = useState()
    const [customerCity,setCustomerCity] = useState()
    const [customerAddress,setCustomerAddress] = useState()

    const changeCountry = (country)=>{
        countries.forEach(element => {
            if(element._id === country){
                setCities(element.children)
                setCustomerCountry(country)
                if(element.children.length)
                    setCustomerCity(element.children[0]._id)
                else
                    setCustomerCity("")    
            }
        });
    }

    const changeCity = (city)=>{
        setCustomerCity(city)
    }
    useEffect(()=>{
        if(isAuthenticated() === false)
        history.push('/')
        loadCustomerInfo()
    },[])

    const [show, setShow] = useState(false);

    const saveChanger = ()=>{
        if(customerCountry && customerCity && customerAddress){
            updateCustomerInfo({country:customerCountry,city:customerCity,address:customerAddress}).then((data)=>{
                loadCustomerInfo()
                setShow(true)
                EditLocalStorage({country:customerCountry,city:customerCity,address:customerAddress})
            })
        }
    }

    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">حسابي</h4>
                                 <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">الرئيسية</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">تعديل عنواني</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-0 order-md-0 mb-4">
                                <div className="sidebar">
                                  
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">القائمة</h6>
                                            </div>
                                            <div className="widget-content pr-0 pl-0">
                                                <div className="category-widget">
                                                    <div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/dashboard">صفحة حسابي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/profile">تعديل بياناتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/orders">قائمة طلباتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/wishlist">قائمة أمنياتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/address">تعديل عنواني</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/password">كلمة المرور</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link onClick={()=>signout(()=>{history.push('/')})} style={{cursor:'pointer'}}>تسجيل الخروج</Link></div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                               
                            
                          
                                <div className="row">
                                    <div className="col-lg-12 col-md-11 mb-4">
                                        <div className="sidebar">
                                        
                                            <div className="widget-wrapper">
                                                <div className="widget-default">
                                                    <div className="widget-header">
                                                        <h6 className="widget-title">تعديل عنواني</h6>
                                                    </div>
                                                    <div className="widget-content">
                                                        <div className="category-widget">                                                                  
                                                            <div class="form-group was-validated">
                                                                <Form.Label>الدولة</Form.Label>
                                                                <div class="select-basic">
                                                                    <select class="form-control" onChange={(e)=>changeCountry(e.target.value)} value={customerCountry}>
                                                                        <option></option>
                                                                        {
                                                                            countries.map((contry,index)=>{
                                                                                return (<option value={contry._id}>{contry.country_title}</option>)
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group was-validated"> 
                                                                <Form.Label>المدينة</Form.Label>
                                                                <div class="select-basic">
                                                                    <select class="form-control" onChange={(e)=>changeCity(e.target.value)} value={customerCity}>
                                                                        {
                                                                            cities.map((city,index)=>{
                                                                                return (<option value={city._id}>{city.country_title}</option>)
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group was-validated"> 
                                                            <Form.Label> العنوان</Form.Label>
                                                                <input onChange={(e)=>setCustomerAddress(e.target.value)} value={customerAddress} type="text" placeholder=" العنوان" className="form-control" required/>
                                                            </div>
                                                            <div className="form-group text-center m-bottom-20">

                                                                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                                                        <Toast.Body className="text-success">تم حفظ التعديلات بنجاح!</Toast.Body>
                                                                    </Toast>

                                                                    <button  onClick={()=>saveChanger()} className="btn btn-secondary" type="button">حفظ التعديل</button>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                            </div>

                          


                        </div>
                    </div>
                </section>

        </Layout>
    )    
}
export default Address;