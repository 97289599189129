/* eslint-disable */
import React,{useState} from 'react'
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
const Footer = ({data,pages})=>{

    const [visible,setVisible] = useState('none')
    const hideShowDiv = ()=>{
        visible === 'none' ? setVisible('block') : setVisible('none')
    }
   
   

    const goToTop = ()=>{
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
    }

    return (
        <React.Fragment>
            <footer className="footer5 footer--bw">
                <div className="footer__big">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 text-center">
                                <div className="widget text_widget">
                                    {data.shop_logo !== "" &&<Link to="/"><img className="footer_logo" src={UPLOADURL + "/" + data.shop_logo} alt="logo" /></Link> }
                               
                                    {data.marrof_logo !== "" && <div><a target="_blank" href={data.marrof_logo}><img className="maroof_logo" src="https://maroof.sa/Content/Stamps/ImageCr.png" alt="Maroof" /></a></div>}

                                </div>

                                <div className="overlay active" style={{display:visible}} onClick={()=>hideShowDiv()}></div>
                                <div className="supportinfo">
                                    <ul>
                                        {data.shop_email !== "" &&
                                        <li><span className="la la-envelope"></span> {data.shop_email}</li>
                                        }
                                        {data.shop_phone !== "" &&
                                        <li><span className="la la-headphones"></span> {data.shop_phone}</li>
                                        }
                                    </ul>
                                </div>
                                
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-lg-center">
                                <div className="widget widget--links">
                                    <h4 className="widget__title">روابط هامة</h4>
                                    <ul className="links">
                                        {
                                            pages.map((page,index)=>{
                                                return (
                                                    <li><Link to={"/page/" + page._id + "/" + page.page_title.trim().replace(/ /g,"-")}>{page.page_title}</Link></li>
                                                )
                                            })
                                        }
                             
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-lg-center">
                                <div className="widget widget--links">
                                    <h4 className="widget__title">حسابي</h4>
                                    <ul className="links">
                                        <li><Link to="/dashboard"> صفحة حسابي</Link></li>
                                        <li><Link to="/profile"> تعديل بياناتي</Link></li>
                                        <li><Link to="/orders">قائمة طلباتي</Link></li>
                                        <li><Link to="/wishlist">قائمة أمنياتي</Link></li>
                                        <li><Link to="/address">تعديل عنواني</Link></li>
                                        <li><Link to="/password">كلمة المرور</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget subcribe--widget">
                                    <p>
                                    {data.contact_info}
                                    </p>
                                    <h4 className="widget__title">ليصلك جديدنا</h4>
                                    <p>تابع حساباتنا على مواقع التواصل الإجتماعي</p>
                               
                                    <div className="widget__social">
                                        <div className="social  ">
                                            <ul className="d-flex flex-wrap">
                                                {data.facebook_link !== "" && <li><a href={data.facebook_link} target="_blank"><span className="fab fa-facebook-f"></span></a></li> }
                                                {data.twiter_link !== "" && <li><a href={data.twiter_link} target="_blank"><span className="fab fa-twitter"></span></a></li> }
                                                {data.instgram_link !== "" && <li><a href={data.instgram_link} target="_blank"><span className="fab fa-instagram"></span></a></li> }
                                                {data.snapchat_link !== "" && <li><a href={data.snapchat_link} target="_blank"><span className="fab fa-snapchat"></span></a></li> }
                                                {data.youtube_link !== "" && <li><a href={data.youtube_link} target="_blank"><span className="fab fa-youtube"></span></a></li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__small text-center">
                    <p className="copyright">Copyright © 2021 <a href="https://yksb.sa">yksb.sa</a> All Rights Reserved.</p>
                </div>
                </footer>
                <div onClick={()=>goToTop()} className="go_top">
                    <span className="la la-angle-up"></span>
                </div>
        </React.Fragment> 
    )
}

export default Footer