/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated} from '../../api/Auth'
import {Card} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {getSinglePage} from '../../api/apiCore'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';  
const Page = ({match})=>{
    const pageId = match.params.pageId
    const [pageData,setPageData] = useState({})
    const getPage = ()=>{
        getSinglePage(pageId).then((data)=>{
            console.log(data)
            setPageData(data)
        })
    }

    useEffect(()=>{
        getPage()
    },[pageId])

    return (
        <Layout title={pageData.page_title} description={pageData.page_content} className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">{pageData.page_title}</h4>
                                 <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{pageData.page_title}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                {ReactHtmlParser(pageData.page_content)}
                            </div>
                        </div>
                    </div>
            </section>

        </Layout>
    )    
}
export default Page;