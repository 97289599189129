/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {Form} from 'react-bootstrap';
import {Alert} from 'react-bootstrap';
import {Toast} from 'react-bootstrap'
import {CartItems,CardTotal,GetDiscount,CartTotalA} from '../../api/Cart'
import {isAuthenticated} from '../../api/Auth'
import {getCountries,getPayments,getShipping,PLaceCart,PlaceNewCart,PlaceOrder,generalConfigrations,checkProducts} from '../../api/apiCore'
import {UPLOADURL} from '../../config'
import { PayPalButton } from "react-paypal-button-v2";
import ImageUploader from "react-images-upload"
import './TextComponentStyle.css'
import Currency from './InnerCommponets/Currency'

const Checkout = ({props,history})=>{

const testTellAPI = ()=>{
    var formdata = new FormData();
formdata.append("ivp_method", "create");
formdata.append("ivp_store", "25495");
formdata.append("ivp_authkey", "114af4c9MgqtxMn5Ww69L4T4");
formdata.append("ivp_amount", "100");
formdata.append("ivp_currency", "SAR");
formdata.append("ivp_test", "1");
formdata.append("ivp_cart", "myoRder2133ID");
formdata.append("ivp_desc", "Purchase description");
formdata.append("return_auth", "http://yksb.sa/auth1");
formdata.append("return_decl", "http://yksb.sa/auth2");
formdata.append("return_can", "http://yksb.sa/auth3");
formdata.append("bill_fname", "MyName");
formdata.append("bill_sname", "MyName");
formdata.append("bill_addr1", "MY Billing address");
formdata.append("bill_phone", "+201021357877");
formdata.append("bill_city", "raide");
formdata.append("bill_country", "SA");
formdata.append("bill_email", "webzerone@gmail.com");
formdata.append("ivp_lang", "ar");

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://secure.telr.com/gateway/order.json", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));


}
const [show, setShow] = useState(false);

const [customerName,setCustomerName] = useState()
const [customerCountry,setCustomerContry] = useState()
const [customerCity,setCustomerCity] = useState()
const [customerAddress,setCustomerAddress] = useState()
//const [customerZipCode,setCustomerZipCode] = useState()
const [customerPhone,setCustomerPhone] = useState()
const [customerEmail,setCustomerEmail] = useState()

const [countries,setCountries] = useState([])

const [selectedCountry,setSelectedCountry] = useState({})
const [selectedCities,setSelectedCities]      = useState([])
const [requireShipping,setRequireShipping] = useState(false)
//const [foundShippingCompany,setFoundShippingCompany] = useState(false)
//const [selectedShippingCompany,setSelectedShippingCompany] = useState([])
const [storeShippingData,setStoreShippingData] = useState([])
const [storeShipping,setStoreShipping] = useState([])
const [storePayments,setStorePayments] = useState([])
const [return_policy,set_return_policy] = useState("")

const loadCountries = ()=>{
    getCountries().then((data)=>{
        setCountries(data)
        //Check products Require Shipping
        if(CartItems() !== false){
            CartItems().map((item,index)=>{
                if(item.product_require_shipping === true)
                    setRequireShipping(true)
            })
        }
        if(isAuthenticated()){
            data.forEach(element => {
                if(element._id === isAuthenticated().customer.country){
                    console.log(element)
                    if(element.children){
                        setSelectedCities(element.children)
                    }
                    
                    //setSelectedCountry(element)

                    // if(element.children){
                    //     element.children.forEach(e=>{
                    //         if(e._id === isAuthenticated().customer.city){
                    //             setSe
                    //         }
                    //     })
                    // }
                }
            });
        }
        generalConfigrations().then((data)=>{
            set_return_policy(data.return_policy)
        })
    })
}
const loadShipping = ()=>{
    getShipping().then((data)=>{
        
        var items = []
        data.forEach(element => {
            element.children.forEach(ele=>{
                if(ele.shipping_countries._id === isAuthenticated().customer.country){
                    ele.shipping_cities.forEach(e=>{
                        if(e._id === isAuthenticated().customer.city){
                            items.push({
                                createdAt: element.createdAt,
                                id: element.id,
                                shipping_compnay: element.shipping_compnay,
                                shipping_logo: element.shipping_logo,
                                updatedAt: element.updatedAt,
                                user_id: element.user_id,
                                children:ele
                            })
                        }
                    })
                }
            })
            
        });
        setStoreShippingData(data)
        setStoreShipping(items)
    })
}
useEffect(()=>{
    if(CartItems() === false || CartItems().length === 0)
            history.push('/')

    loadCountries()
    
    if(isAuthenticated()){
        setCustomerName(isAuthenticated().customer.name)
        setCustomerContry(isAuthenticated().customer.country)
        setCustomerCity(isAuthenticated().customer.city)
        setCustomerAddress(isAuthenticated().customer.address)
        setCustomerPhone(isAuthenticated().customer.phone)
        setCustomerEmail(isAuthenticated().customer.email)

        loadShipping()
        loadPayments()
    }

    //testTellAPI()

    if(CartItems() !== false && CartItems().length !== 0)
    {
        var items = CartItems()
        var productsToCheck = []
        
        for(var y=0;y<items.length;y++){
            var index = y
            if('productAttributes' in items[index]){
                var attr = []
                if(items[index].productAttributes.length > 0){
                    for(var i=0;i<items[index].productAttributes.length;i++){
                        for(var x=0;x<items[index].productAttributes[i].attributeValues.length;x++){
                            if("select" in items[index].productAttributes[i].attributeValues[x]){
                                if(items[index].productAttributes[i].attributeValues[x].select === true){
                                    // if(items[index].productAttributes[i].attributeValues[x].attribute_value_quantity >= items[index].q){
                                        
                                    // }
                                    attr.push({
                                        attributeId:items[index].productAttributes[i]._id,
                                        attributeValueId:items[index].productAttributes[i].attributeValues[x]._id
                                    })
                                    break
                                }
                            }
                        }
                        
                    }
                }
                productsToCheck.push({
                    productId  :items[index].id,
                    quantity   :items[index].q,
                    attributes:attr
                })
            }
            else {
                productsToCheck.push({
                    productId  :items[index]._id,
                    quantity   :items[index].q
                })
            }
        }
        //checkProducts(productsToCheck)
        for (let index = 0; index < productsToCheck.length; index++) {
            checkProducts(productsToCheck[index]).then((data)=>{
                console.log(data)
                if('error' in data){
                    
                    for(var y=0;y<items.length;y++)
                    {
                        if(items[y].id === data.productId){
                            console.log(data)
                            setShow(true)

                            setCheckoutMessage('  لا يوجد مخزون كافي من المنتج يرجي مراجعه الكمية  = ' + items[y].product_title)
                        }
                        
                    }
                    
                }
                else{
                    setCheckProductsQuantity(true)
                }
            })
        }           
    }

},[])
//Product Checking
const [checkProductsQuantity,setCheckProductsQuantity] = useState(false)
//const [show,setShow] = useState(false)
const [toastMessage,setToastMessage] = useState('')

const changeCountry = (country)=>{
    countries.forEach(element => {
        if(element._id === country){
            if(element.children.length){
                setSelectedCities(element.children)
                console.log(element)
                setCustomerCity(element.children[0]._id)
                changeShipping(country,element.children[0]._id)
            }
            else{
                setSelectedCities([])
                setCustomerCity("")

                setStoreShipping([])
            }
            setCustomerContry(country)
        }
    });
    setSelectedShipping({})
    setShippingValue(0)
}
const changeCity = (city)=>{
    if(city){
        setCustomerCity(city)
        changeShipping(customerCountry,city)
    }
        
    else
        setCustomerCity("")  
        
    setSelectedShipping({}) 
    setShippingValue(0)  
}

const changeShipping = (country,city)=>{
        var items = []
        storeShippingData.forEach(element => {
            element.children.forEach(ele=>{
                if(ele.shipping_countries._id === country){
                    ele.shipping_cities.forEach(e=>{
                        if(e._id === city){
                            items.push({
                                createdAt: element.createdAt,
                                id: element.id,
                                shipping_compnay: element.shipping_compnay,
                                shipping_logo: element.shipping_logo,
                                updatedAt: element.updatedAt,
                                user_id: element.user_id,
                                children:ele
                            })
                        }
                    })
                }
            })
            
        });
        setStoreShipping(items)
}
const [selectedShipping,setSelectedShipping] = useState({})
const [shippingValue,setShippingValue] = useState(0)
const shippingChange = (ship)=>{
    setSelectedShipping(ship)
    setShippingValue(ship.children.shipping_price)
    console.log(ship)
}

const [payments,setPayments] = useState([])
const [selectedPayment,setSelectedPayment] = useState({})

const [paymentBanks,setPaymentsBank]    = useState([])
const [paymentPayPal,setPaymentsPayPal] = useState(null)
const [paymentTelr,setPaymentsTelr]     = useState(null)
const [paymentStcPay,setPaymentsStcPay] = useState(null)

const loadPayments = ()=>{
    getPayments().then((data)=>{
        setPayments(data)
        var banks = []
        data.forEach(element => {
            if(element.payment_type === "Bank")
            {
                banks.push(element)
            }
            else if(element.payment_type === "PayPal"){
                if(element.payment_activation === true){
                    setPaymentsPayPal(element)
                }
            }
            else if(element.payment_type === "Telr"){
                if(element.payment_activation === true){
                    setPaymentsTelr(element)
                }
            }
            else if(element.payment_type === "StcPay"){
                if(element.payment_activation === true){
                    setPaymentsStcPay(element)
                }
            }
        });
        setPaymentsBank(banks)
    })
}
const [confirm,setConfirm] = useState(false)
const confirmCheck = ()=>{
    setConfirm(!confirm)
}
const [checkoutMessage,setCheckoutMessage] = useState("")
const [pictures, setPictures] = useState([]);

const onDrop = picture => {
    console.log(picture[0])
    setPictures(picture);
};
const [orderButtonStatus,set_orderButtonStatus] = useState(false)
const RequestProcess = ()=>{
    if(!customerName || !customerCountry || !customerCity || !customerAddress || !customerPhone || !customerEmail){
        setCheckoutMessage(" يرجى التحقق من البيانات الاساسية ")
    }
    else if(requireShipping && JSON.stringify(selectedShipping)==JSON.stringify({})){
        setCheckoutMessage(" يرجى اختيار طريقة الشحن ")
    }
    // else if(CardTotal() > 0 && JSON.stringify(selectedPayment)==JSON.stringify({})){ // check payment
    //     setCheckoutMessage(" يرجى اختيار طريقة الدفع ")
    // }
    else if(confirm === false){
        setCheckoutMessage(" يرجى الموافقة على الإقرار ")
    }
    else{
        setCheckoutMessage("")
        set_orderButtonStatus(true)
        PlaceNewCart().then((cartD)=>{
            //Procees The order
            if(CartItems() !== false){
                CartItems().map((item,index)=>{
                    var itemToPlace = {}
                    var extraAttribute = []
                    //console.log(item)
                    if(item.productAttributes.length > 0)
                    {
                        item.productAttributes.map((it,ind)=>{
                            it.attributeValues.map((attributeValue,AttributeIndex)=>{
                                

                                if(attributeValue.select === true)
                                {
                                    extraAttribute.push({
                                        "attributeId":attributeValue.attribute_id,
                                        "attributeValueId":attributeValue._id,
                                        "attribute_name":it.attribute_name,
                                        "attribute_type":it.attribute_type,
                                        "attribute_value":attributeValue.attribute_value_name,
                                        "attribute_value_extra_price":attributeValue.attribute_value_extra_price
                                    })
                                }
                            })
                        })
                    }
                    itemToPlace = {
                        "productId":item._id,
                        "product_quantity":item.q,
                        "attributes":extraAttribute,
                        "product_price":item.product_price
                    }
                    PLaceCart(itemToPlace).then((data)=>{
                        if((index+1) === CartItems().length){
                            //Place the order
                            PlaceOrder({
                                name:customerName,
                                country:customerCountry,
                                city:customerCity,
                                address:customerAddress,
                                phone:customerPhone,
                                email:customerEmail,
                                totalcost:parseFloat((CartTotalA() + shippingValue).toFixed(2)),
                                order_shipping:requireShipping?selectedShipping:null,
                                order_payment:null,
                                order_coupon:GetDiscount() === false ? {} : GetDiscount()
                            }).then((data)=>{
                                //empty 
                                localStorage.removeItem('cartItems')
                                localStorage.removeItem('coupon')
                                history.push(`/order/${data._id}`)

                            })
                        }
                    })
                })
            }
        })
        
    }
    setShow(true)
}

return (
    <Layout title="تنفيذ الطلب" description="تنفيذ الطلب" className="container">
            
            
    
            <section className="breadcrumb_area breadcrumb1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                    <h4 className="page_title">تنفيذ الطلب</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                             <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">تنفيذ الطلب</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    isAuthenticated() === false && 
                    <section className="checkout-wrapper p-top-90 p-bottom-110 m-bottom-125">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-md-12 mb-4">
                                    <Alert variant="info">
                                    لديك عضوية؟ [ <a href="/signin">إضغط هنا للدخول</a> ] او [ <a href="/signup">اضغط هنا للتسجيل</a> ]  
                                    </Alert>
                            </div>  
                            </div>
                    </div>
                    </section>        
                }
                {
                    isAuthenticated() !== false &&
                    <section className="checkout-wrapper p-top-90 p-bottom-110">
                    <div className="container">
                        <div className="row">    
                            <div className="col-lg-12 col-md-12 mb-4">
                                <Alert variant="success">
                                <strong>مرحباً {isAuthenticated().customer.name}</strong>
                                     <div>يمكنك تحديث معلومات عنوان الشحن والتوصيل من صفحة حسابك أو من البيانات أدناه إن رغبت.</div>
                                </Alert>
                                {
                                    checkoutMessage && 
                                    <Alert variant="danger">
                                        {checkoutMessage}
                                    </Alert>
                                }
                            </div> 
                            <div className="col-lg-8 col-md-8 mb-4">
                                <div className="card">
                                    <div className="card-body">
                                    <h4 className="color-primary">الشحن والتوصيل</h4>


                                    <form>
                                    <h6><i className="fa fa-map-marker color-secondary" aria-hidden="true"></i> عنوان الشحن </h6>
                                                                    <div className="form-group was-validated">
                                                                        <Form.Label>الإسم</Form.Label>
                                                                        <input value={customerName} onChange={(e)=>{setCustomerName(e.target.value)}} type="text" placeholder="الإسم الكامل" className="form-control" />
                                                                    </div>
                                                                    <div className="form-group was-validated">
                                                                        <Form.Label>الدولة</Form.Label>
                                                                        <div className="select-basic">
                                                                            <select className="form-control" value={customerCountry} onChange={(e)=>changeCountry(e.target.value)}>
                                                                                {
                                                                                    countries.map((country,index)=>{
                                                                                        return(<option value={country._id}>{country.country_title}</option>)
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group was-validated"> 
                                                                        <Form.Label>المدينة</Form.Label>
                                                                        <div className="select-basic">
                                                                            <select className="form-control" value={customerCity} onChange={(e)=>changeCity(e.target.value)}>
                                                                                {
                                                                                    selectedCities.map((country,index)=>{
                                                                                        return(<option value={country._id}>{country.country_title}</option>)
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group was-validated"> 
                                                                        <Form.Label>الحي والشارع</Form.Label>
                                                                        <input value={customerAddress} onChange={(e)=>{setCustomerAddress(e.target.value)}}  type="text" placeholder="الحي والشارع" className="form-control" />
                                                                    </div>
                                                                   
                                                                    <div className="form-group was-validated">
                                                                        <Form.Label>الجوال</Form.Label>
                                                                        <input value={customerPhone} onChange={(e)=>{setCustomerPhone(e.target.value)}}  type="text" placeholder="الجوال" className="form-control" />
                                                                    </div>
                                                                    <div className="form-group was-validated">
                                                                        <Form.Label>البريد الإلكتروني</Form.Label>
                                                                        <input value={customerEmail} onChange={(e)=>{setCustomerEmail(e.target.value)}}  type="text" placeholder="البريد الإلكتروني" className="form-control" />
                                                                    </div>
                                                                  
                                                                   
                                                                </form>
                                            {
                                                requireShipping === true && storeShipping.length === 0 && <Alert variant="danger" className="mt-4"> عفواً, لا تتوفر خدمة الشحن للمدينة المختارة </Alert>
                                            }                    
                                            {requireShipping === true && storeShipping.length > 0 &&
                                                <div className="row mt-4">
                                                    
                                                    <h6 className="mb-4 mt-4"><i className="fa fa-truck color-secondary" aria-hidden="true"></i> اختر طريقة الشحن </h6>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead className="table-gray">
                                                                <tr>
                                                                    <th scope="col">طريقة الشحن</th>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">زمن التوصيل (يوم)</th>
                                                                    <th scope="col">رسوم الشحن</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    storeShipping.map((ship,index)=>{
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                
                                                                                {ship.shipping_logo !== "" && <img width={60} src={UPLOADURL + "/" + ship.shipping_logo} alt="logo" /> }
                                                                                
                                                                                </td>
                                                                                <td>
                                                                                    <div className="custom-control custom-radio custom-control-inline">
                                                                                    <label className="ml-3">
                                                                                        <input type="Radio" onClick={()=>shippingChange(ship)} name="shippping"/></label>
                                                                                        <label className="ml-3">
                                                                                            {ship.shipping_compnay}
                                                                                        </label>
                                                                                        
                                                                                        
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td>{ship.children.shipping_time}</td>
                                                                                <td>{ship.children.shipping_price} <Currency/></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>    
                                            }                   
                                            
                              
                                  




                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 mb-4">
                                <div className="card">
                                    <div className="card-body">
                                    <h4 className="color-primary">ملخص الطلب</h4>

                                         <div className="cart-table cart-table--2 table-responsive">
                                                <table className="table review">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"><span>المنتج</span></th>
                                                            <th scope="col" className="text-center"><span>الكمية</span></th>
                                                            <th scope="col" className="text-center"><span>السعر</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                CartItems() !== false &&        
                                                CartItems().map((cartItems,index)=>{
                                                    return (
                                                        <tr className="cart-single">
                                                            <th scope="row">
                                                                <div className="name">
                                                                    {/* <img src="img/thumb1.jpg" alt=""> */}
                                                                    {cartItems.product_title}
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div className="quantity text-center">
                                                                    <div className="total-item">
                                                                    <span>{cartItems.q}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="subtotal text-center">
                                                                    <span>{cartItems.product_price} <Currency/></span>
                                                                </div>
                                                            </td>
                                                          
                                                        </tr>

                                                    )
                                                    })

                                                    }
                                                  
                                                        <tr className="cart-bottom">
                                                            <td colspan="3">
                                                                {/* <div className="total-amount">
                                                                    الشحن: <span>$50.00</span>
                                                                </div>
                                                            
                                                                <div className="total-amount">
                                                                    القيمة المضافة: <span>$7.00</span>
                                                                </div> */}
                                                           
                                                                <h6 className="total-amount text-right mt-2">
                                                                    الإجمالي: <span>{(CardTotal() + shippingValue).toFixed(2)} <Currency/></span>
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>





                                    </div>
                                </div>
                            </div>
                        </div>
                 

                        <div className="shipping-method m-top-55 p-bottom-60">
                           
                                <div className="custom-control custom-checkbox checkbox-secondary agreement">
                                    {/* <input type="checkbox" className="custom-control-input" id="customCheck111" checked=""> */}

                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" onClick={()=>confirmCheck()}  label="إقرار العميل" required/>
                                    </Form.Group>



                              
                                    <div>1- بعد شحن الطلب يتولى العميل متابعة الطلب مع شركة الشحن لضمان استلامها في الوقت المحدد.</div>
                                    <div>2- بالنقر على زر ( تنفيذ الطلب ) أدناه، فأنت تؤكد أنك قرأت وقبلت ووافقت على <a href={return_policy} target="_blank">سياسة الاستبدال والارجاع والإلغاء</a>.</div>
  
                                </div>
                         </div>




                       
                         <div className="float-left">    
                                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                    <Toast.Body className="text-danger"> {checkoutMessage}</Toast.Body>
                                </Toast>
                        </div>  



                    <div className="action-btns d-flex m-top-30 m-bottom-60">

                       
                        <div>   
                                <a href="/viewcart" className="btn btn-outline-secondary btn-icon icon-right"><i className="la la-angle-right"></i> عودة لسلة المشتريات</a>
                                {/* <a href="/checkoutconfirm" className="btn btn-primary mr-auto btn-icon icon-left"></a> */}
                         </div>
                                
                                {checkProductsQuantity === true && <button disabled={orderButtonStatus} onClick={()=>RequestProcess()} className="btn btn-primary mr-auto btn-icon icon-left">المتابعة للدفع <i className="la la-angle-left"></i></button>}
                                
                                
                    </div>

                

                </div>
                </section>
                }



        </Layout>
    )
}

export default Checkout