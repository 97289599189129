export const FavoritItems = ()=>{

    if(typeof window == 'undefined'){
        return false
    }


    if(localStorage.getItem('favoritItems')){
        return JSON.parse(localStorage.getItem('favoritItems'))
    }
    else{
        return false;
    }
}
export const AddFavoritItem = (item,next)=>{

    if(typeof window == 'undefined'){
        return false
    }
    //localStorage.removeItem('cartItems')

    if(localStorage.getItem('favoritItems')){
        var items = []
        items =  JSON.parse(localStorage.getItem('favoritItems'))
        //check if item exits
        var exits = false
        for(var i=0;i<items.length;i++){
            if(items[i]._id === item._id)
                exits = true
        }
        if(exits === false)
            items.push(item)
        localStorage.setItem('favoritItems',JSON.stringify(items))
        next()
    }
    else{
        item = [item]
        localStorage.setItem('favoritItems',JSON.stringify(item))
        next()
    }
}

export const RemoveFavoritItem = (index,next)=>{
    console.log(index)
    var items = []
    if(localStorage.getItem('favoritItems')){
        items =  JSON.parse(localStorage.getItem('favoritItems'))
        items.splice(index,1)
        if(items.length > 0)
            localStorage.setItem('favoritItems',JSON.stringify(items))
        else
            localStorage.removeItem('favoritItems')    
        
        next()
    }
    else{
        return false;
    }
}