/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'

import {CartItems,RemoveCartItem,CardTotal,AddMoreItems,AddLessItems,AddDiscount} from '../../api/Cart'
import {loadCoupon} from '../../api/apiCore'
import {UPLOADURL} from '../../config'
import {Link} from 'react-router-dom'
import Currency from './InnerCommponets/Currency'
import {Toast} from 'react-bootstrap'
const ViewCart = ({history})=>{
    useEffect(()=>{
        if(CartItems() === false || CartItems().length === 0)
        history.push('/')
    },[CartItems()])
    const RemoveCardItem = (index)=>{
        RemoveCartItem(index,()=>{history.push('/viewcart')})
    }

    const AddMoreItemsCheck = (index)=>{
        if(CartItems() !== false && CartItems().length !== 0)
        {
            var items = CartItems()
            var checkQuantity = true;
            if('productAttributes' in items[index]){
                if(items[index].productAttributes.length > 0){
                    for(var i=0;i<items[index].productAttributes.length;i++){
                        for(var x=0;x<items[index].productAttributes[i].attributeValues.length;x++){
                            if("select" in items[index].productAttributes[i].attributeValues[x]){
                                if(items[index].productAttributes[i].attributeValues[x].select === true){
                                    if(items[index].productAttributes[i].attributeValues[x].attribute_value_quantity < items[index].q+1){
                                        checkQuantity = false
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if(checkQuantity)
                AddMoreItems(index,()=>{history.push('/viewcart')})
            else{
                setShow(true)
                setToastMessage('لا يوجد مخزون كافي من المنتج  = ' + items[index].product_title)
            }    
        }
        
    }
    
    const [show,setShow] = useState(false)
    const [toastMessage,setToastMessage] = useState('')
    const [coupon,set_coupon] = useState('')

    const [activeCoupon,setActiveCoupon] = useState({discountValue:0})

    const checkCoupon = ()=>{
        if(coupon.length > 0){
            loadCoupon({coupon:coupon}).then((data)=>{
                if('error' in data){
                    setShow(true)
                    setToastMessage('كوبون غير صالح للاستخدام')
                }
                else{
                    //setActiveCoupon(data)
                    console.log(data)
                    if(data.coupon_min_amount > CardTotal()){
                        setShow(true)
                        setToastMessage(<div>أقل قيمة مشتريات لهذا الكوبون = {data.coupon_min_amount} <Currency/></div>) 
                    }
                    else{
                        if(data.coupon_type === 'Fixed' && CardTotal() < data.coupon_type_value){
                            data.coupon_type_value = CardTotal()
                        }
                        if(data.coupon_type === 'Fixed'){
                            //setShow(true)
                            const discount =  data.coupon_type_value;
                            //setToastMessage('مجموع السلة بعد الخصم   = ' + discount)
                            data.discountValue = discount
                            //setActiveCoupon(data)
                            AddDiscount(data,()=>{history.push('/viewcart')})
                        }

                        else if(data.coupon_type === 'Percent'){
                            //setShow(true)
                            const discount = ((CardTotal() * data.coupon_type_value ) / 100)
                            //setToastMessage('مجموع السلة بعد الخصم   = ' + discount)
                            data.discountValue = discount
                            //setActiveCoupon(data)
                            AddDiscount(data,()=>{history.push('/viewcart')})
                        }
                    }
                }
            })
        }
    }

    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
            <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                <h4 className="page_title">سلة المشتريات</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">سلة المشتريات</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {CartItems() &&
                <section className="cart-wrapper section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cart-table table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"><span>المنتج</span></th>
                                                <th scope="col"><span>الكمية</span></th>
                                                <th scope="col"><span>السعر</span></th>
                                                <th scope="col"><span>الإجمالي</span></th>
                                                <th scope="col" className="text-center"><span>حذف</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                CartItems().map((cartItems,index)=>{
                                                    return (
                                                        <tr className="cart-single alert alert-dismissible fade show">
                                                            <th scope="row">
                                                                <div className="name item_thumb">
                                                                {cartItems.productImages.length > 0 &&
                                                                    <img width={80} src={UPLOADURL+"/"+cartItems.productImages[0].product_image} alt={cartItems.product_price} className="ml-2" />
                                                                }
                                                                <Link to={"product/"+cartItems._id}>{cartItems.product_title}</Link>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div className="quantity text-right">
                                                                    <div class="total-item">
                                                                        <span onClick={()=>AddLessItems(index,()=>{history.push('/viewcart')})} className="minus pcount m-left-10" style={{cursor:'pointer'}}><i class="las la-minus-circle"></i></span>
                                                                        <span  id="numberInput" >{cartItems.q}</span>
                                                                        <span onClick={()=>AddMoreItemsCheck(index)} className="plus pcount m-right-10" style={{cursor:'pointer'}}><i class="las la-plus-circle"></i></span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="price">
                                                            <span>{cartItems.product_price} <Currency/></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="subtotal">
                                                                    <span>{(cartItems.product_price * cartItems.q).toFixed(2)} <Currency/></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div onClick={()=>RemoveCardItem(index)} className="text-center" style={{cursor:'pointer'}}>X</div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                            
                                            <tr className="cart-bottom">
                                                <td colspan="3">
                                                    <div className="form-inline">
                                                        <input value={coupon} onChange={(e)=>set_coupon(e.target.value)} type="text" className="form-control mb-3" placeholder="كود الخصم" />
                                                        <button onClick={()=>checkCoupon()} className="btn btn-primary mr-sm-3 mb-3">أضف كود الخصم</button>
                                                    </div>
                                                </td>
                                                <td colspan="2">
                                                    <div className="total-amount text-center">
                                                    الإجمالي: <span>{CardTotal().toFixed(2)} <Currency/></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                        <Toast.Body className="text-danger">{toastMessage}</Toast.Body>
                                    </Toast>
                                    <div className="cart-actions m-top-50">
                                        <div>
                                        <a href="/" className="btn btn-outline-secondary btn-icon icon-right"><i className="la la-angle-right"></i> عودة لاكمال التسوق</a>
                                        </div>
                                        <div className="mr-auto">
                                            <Link to="/checkout" className="btn btn-primary">تنفيذ الطلب</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {CartItems() === false &&
                <div>No Items Found</div>
            }
            {CartItems().length === 0 &&
                <div>No Items Found</div>
            }
            
        </Layout>
    )
}

export default ViewCart