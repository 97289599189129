/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated} from '../../api/Auth'
import {Card} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {checkPayment} from '../../api/apiCore'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';  
const queryString = require('query-string')
import { useLocation,Link } from 'react-router-dom'
const PaymentProcess = (props)=>{
    const { search } = useLocation()
    const values = queryString.parse(search)
    //{amount: "900", id: "46e48429-7bc1-4ca5-8a1f-c6a66f55799f", message: "Allowed time frame for transaction has been expired.", status: "failed"}
    const [id,set_id] = useState('')
    const [message,set_message] = useState('')
    const [status,set_status] = useState('')

    const {match,history} = props
    const categoryId = match.params.categoryId

    const [pageData,setPageData] = useState({})
    const getOrderData = ()=>{
        if('id' in values && 'amount' in values && 'message' in values && 'status' in values){
            if(values.status === 'paid'){
                checkPayment({id:values.id}).then((data)=>{
                    if(JSON.stringify(data)!==JSON.stringify({})){
                        if('success' in data)
                            set_message(<div className="alert alert-success">شكراً، تم اكمال عملية الدفع بنجاح يمكنك متابعة حالة طلبك من خلال صفحة طلباتي.</div>)
                        else
                            set_message(<div className="alert alert-danger">عفواً، حدث خطأ في عمليه الدفع يرجي المحاوله مرة أخرى من خلال صفحة طلباتي.</div>)  
                    }
                    
                })
            }
            else{
                set_message(<div className="alert alert-danger">عفواً، حدث خطأ في عمليه الدفع يرجي المحاوله مرة أخرى من خلال صفحة طلباتي.</div> )
            }
        }
        else {
            history.push('/')
        }
    }

    useEffect(()=>{
        if(!isAuthenticated())
            history.push('/')
        getOrderData()
    },[])

    return (
        <Layout title="الدفع" description="الدفع" className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">الدفع</h4>
                                 <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">الدفع</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-150 p-bottom-150 m-bottom-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                {message}

                                
                                <div className="text-center"><Link to="/orders" className="btn btn-primary mt-3">قائمة طلباتي</Link></div>

                            </div>
                        </div>
                    </div>
            </section>

        </Layout>
    )    
}
export default PaymentProcess;