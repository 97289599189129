
export const authenticate = (data,next)=>{
    if(typeof window !== 'undefined'){
        localStorage.setItem('jwt',JSON.stringify(data))
        next()
    }
}
export const isAuthenticated = ()=>{
    if(typeof window == 'undefined'){
        return false
    }

    if(localStorage.getItem('jwt')){
        return JSON.parse(localStorage.getItem('jwt'))
    }
    else{
        return false;
    }
}
export const EditLocalStorage = (d)=>{
    if(typeof window == 'undefined'){
        return false
    }
    if(localStorage.getItem('jwt')){
        var data = JSON.parse(localStorage.getItem('jwt'));
        if("name" in d){
            data.customer.name = d.name
        }
        if("address" in d){
            data.customer.address = d.address
        }
        if("city" in d){
            data.customer.city = d.city
        }
        if("country" in d){
            data.customer.country = d.country
        }
        if("phone" in d){
            data.customer.phone = d.phone
        }
        localStorage.setItem('jwt',JSON.stringify(data))
    }

}
export const signout = (next)=>{
    if(typeof window !== 'undefined'){
        localStorage.removeItem('jwt')
        localStorage.removeItem('cartItems')
        localStorage.removeItem('favoritItems')
        localStorage.removeItem('coupon')
        next()
        // return fetch(`${API}/signout`,{
        //     method:'GET'
        // })
        // .then((response)=>{
        //     console.log(response)
        // })
        // .catch((error)=>{
        //     console.log(error)
        // })
    }
}