/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {Form} from 'react-bootstrap';
import {Alert,Badge} from 'react-bootstrap';
import {Toast} from 'react-bootstrap';
import {CartItems,CardTotal} from '../../api/Cart'
import {isAuthenticated} from '../../api/Auth'
import {getPayments,singleOrder,updateOrder,getSingleCountries} from '../../api/apiCore'
import {UPLOADURL} from '../../config'
import { PayPalButton } from "react-paypal-button-v2";
import ImageUploader from "react-images-upload"
import './TextComponentStyle.css'
import {Button,Modal,Spinner} from 'react-bootstrap';
import Currency from './InnerCommponets/Currency'
import stc from '../assets/img/pay/stc-c.png';
import telr from '../assets/img/pay/telr-c.png';
import mada from '../assets/img/pay/mada-c.png';
import sadad from '../assets/img/pay/sadad-c.png';
import master from '../assets/img/pay/master-c.png';
import visa from '../assets/img/pay/visa-c.png';
import apple from '../assets/img/pay/apple-c.png';

const Order = (props)=>{
const {match,location,history} = props;

const [show, setShow] = useState(false);

const orderId = match.params.orderId
const [orderData,setOrderData] = useState({})
const [country,setCountry] = useState("")
const [city,setCity] = useState("")
const [orderShipping,setOrderShipping] = useState({})
const [order_items,set_order_items] = useState([])
const [discount,setDiscount] = useState(0)
const loadOrderData = ()=>{
    singleOrder(orderId).then((data)=>{
        setOrderData(data)

        if(data.order_shipping)
            data.order_shipping.shipping_price = data.order_shipping.children.shipping_price

        setOrderShipping(data.order_shipping)

        set_order_items(data.ordercart[0].cartitems)
        
        console.log(data)

        getSingleCountries(data.country).then((country)=>{
            setCountry(country.country_title)
        })
        getSingleCountries(data.city).then((city)=>{
            setCity(city.country_title)
        })

        if('order_coupon' in data && 'coupon_title' in data.order_coupon){
            if(data.order_coupon.coupon_type === 'Fixed'){
                //setShow(true)
                setDiscount(data.order_coupon.coupon_type_value);
            }
            else if(data.order_coupon.coupon_type === 'Percent'){
                setDiscount(((data.totalcost * data.order_coupon.coupon_type_value ) / 100))
            }
        }
        
    })
}


useEffect(()=>{
    if(isAuthenticated === false)
        history.push('/')
    // if(CartItems() === false || CartItems().length === 0)
    //         history.push('/')
    loadOrderData()
    loadPayments()

},[orderId])

const [payments,setPayments] = useState([])
const [selectedPayment,setSelectedPayment] = useState({})

const [paymentBanks,setPaymentsBank]    = useState([])
const [paymentPayPal,setPaymentsPayPal] = useState(null)
const [paymentTelr,setPaymentsTelr]     = useState(null)
const [paymentStcPay,setPaymentsStcPay] = useState(null)
const [paymentCashOnDelivery,setPaymentsCashOnDelivery] = useState(null)
const [paymentMoyasar,setPaymentsMoyasar] = useState(null)

const loadPayments = ()=>{
    getPayments().then((data)=>{
        setPayments(data)
        var banks = []
        data.forEach(element => {
            if(element.payment_type === "Bank")
            {
                banks.push(element)
            }
            else if(element.payment_type === "PayPal"){
                if(element.payment_activation === true){
                    setPaymentsPayPal(element)
                }
            }
            else if(element.payment_type === "Telr"){
                if(element.payment_activation === true){
                    setPaymentsTelr(element)
                }
            }
            else if(element.payment_type === "StcPay"){
                if(element.payment_activation === true){
                    setPaymentsStcPay(element)
                }
            }
            else if(element.payment_type === "CashOnDelivery"){
                if(element.payment_activation === true){
                    setPaymentsCashOnDelivery(element)
                }
            }
            else if(element.payment_type === 'Moyasar'){
                if(element.payment_activation === true){
                    setPaymentsMoyasar(element)
                }
            }
        });
        setPaymentsBank(banks)
    })
}

const [checkoutMessage,setCheckoutMessage] = useState("")
const [pictures, setPictures] = useState([]);

const [imageMessage1,setImageMessage1] = useState("")
const [imageMessage2,setImageMessage2] = useState("")
const onDrop = picture => {
    setPictures(picture);
    setImageMessage1(<p class="text-success text-center">تم اختيار الصورة</p>)
};

const onDrop2 = picture => {
    
    setPictures(picture);
    setImageMessage2(<p class="text-success text-center">تم اختيار الصورة</p>)
};
const [finishOrder,setFinishOrder] = useState("")

//visi fields
const [v_name,set_v_name]  = useState('')
const [v_card,set_v_card]  = useState('')
const [v_month,set_v_month]= useState('')
const [v_year,set_v_year]  = useState('')
const [v_cvc,set_v_cvc]    = useState('')
const [orderButtonStatus,set_orderButtonStatus] = useState(false)
const RequestProcess = ()=>{
    
    // else if(CardTotal() > 0 && JSON.stringify(selectedPayment)==JSON.stringify({})){ // check payment
    //     setCheckoutMessage(" يرجي اختيار طريقة الدفع ")
    // }
    setCheckoutMessage("")
    if(JSON.stringify(selectedPayment)==JSON.stringify({})){
        setCheckoutMessage(" يرجي اختيار طريقة الدفع ")
    }
    else{
        if(selectedPayment.payment_type === "Bank"  && pictures.length === 0){
            setCheckoutMessage(" يرجي رفع صورة من الحواله الخاصة بالطلب ")
        }
        else if(selectedPayment.payment_type === "StcPay" && pictures.length === 0){
            setCheckoutMessage(" يرجي رفع صورة من الحواله الخاصة بالطلب ")
        }
        else if(pictures.length > 0 && (selectedPayment.payment_type === "Bank" || selectedPayment.payment_type === "StcPay")){
            set_orderButtonStatus(true)
            //Update the order 
            updateOrder({
                id:orderId,
                payment_type:selectedPayment.payment_type,
                order_transfer:pictures[0]
            }).then((data)=>{
                loadOrderData()
                setFinishOrder(<div className="col-lg-12 col-md-12 mb-4 mt-4">
                <Alert variant="success">
                    <div>تم ارسال الطلب بنجاح</div>
                </Alert>
            </div>)
            })
        }
        // else if(selectedPayment.payment_type === "Telr"){
        //     //handleOpenModal()
        // }
        else if(selectedPayment.payment_type === 'Moyasar'){
            if(v_name.length === 0){
                setCheckoutMessage(" يرجي اضافة اسم حامل البطاقة ")
            }
            else if(v_card.length < 14 || v_card.length > 16){
                setCheckoutMessage("  خطا برقم البطاقة ")
            }
            else if(v_year.length !== 2){
                setCheckoutMessage(" خطا في سنه الانتهاء ")
            }
            else if(v_month.length !== 2){
                setCheckoutMessage(" خطا في شهر الانتهاء ")
            }
            else if(v_cvc.length !== 3){
                setCheckoutMessage(" خطا في الرقم السري ")
            }
            else{
                set_orderButtonStatus(true)
            // <input type="hidden" name="callback_url" value={"https://"+window.location.hostname+"/payments_process"} />
            // <input type="hidden" name="publishable_api_key" value={paymentMoyasar.payment_info.publishable} />
            // <input type="hidden" name="amount" value={(orderData.totalcost - discount) * 100} />
            // <input type="hidden" name="source[type]" value="creditcard" />
            // <input type="hidden" name="description" value={orderId} />
                var formdata = new FormData();
                formdata.append("callback_url", "https://"+window.location.hostname+"/payments_process");
                formdata.append("publishable_api_key", paymentMoyasar.payment_info.publishable);
                formdata.append("amount",((orderData.totalcost - discount) * 100))
                formdata.append("source[type]", "creditcard");
                formdata.append("description",orderId)
                formdata.append("source[name]", v_name);
                formdata.append("source[number]", v_card);
                formdata.append("source[month]", v_month);
                formdata.append("source[year]", v_year);
                formdata.append("source[cvc]",v_cvc);
                fetch('https://api.moyasar.com/v1/payments', {
                    method: 'post',
                    body:    formdata//JSON.stringify(body),
                    //headers: { 'Content-Type': 'application/json' },
                })
                .then((res)=>{
                    if(res.status !== 201)
                        return {error:1}
                    else    
                        return res.json()
                })
                .then((json)=>{
                    if('error' in json)
                        setCheckoutMessage(" خطا في عمليه الدفع يرجي المحاولة في وقت لاحق ")
                    else{
                        if('source' in json){
                            if('transaction_url' in json.source){
                                //window.location = json.source.transaction_url;
                                updateOrder({
                                    id:orderId,
                                    payment_type:selectedPayment.payment_type,
                                    details:json.id
                                }).then((data)=>{
                                    window.location = json.source.transaction_url
                                })
                            }
                            else{
                                setCheckoutMessage(" خطا في عمليه الدفع يرجي المحاولة في وقت لاحق ")
                            }
                        }
                        else{
                            setCheckoutMessage(" خطا في عمليه الدفع يرجي المحاولة في وقت لاحق ")
                        }
                    }    
                });
            }
        }
        else if(selectedPayment.payment_type === "CashOnDelivery"){
            set_orderButtonStatus(true)
            updateOrder({
                id:orderId,
                payment_type:selectedPayment.payment_type,
            }).then((data)=>{
                loadOrderData()
                setFinishOrder(<div className="col-lg-12 col-md-12 mb-4 mt-4">
                <Alert variant="success">
                    <div>تم ارسال الطلب بنجاح</div>
                </Alert>
            </div>)
            })
        }
    }
    //console.log(selectedPayment)
    setShow(true)
}
const [telrOrder,setTelrOrder] = useState({})
const [showModal,setShowModal] = useState(false)
const handleOpenModal = ()=>{
    return;
    console.log(orderData)
    setShowModal(true)

    //Telr Order
    var formdata = new FormData();
    formdata.append("ivp_method", "create");
    formdata.append("ivp_store", selectedPayment.payment_info.store_id);
    formdata.append("ivp_authkey", selectedPayment.payment_info.authentication_key);
    formdata.append("ivp_amount", orderData.totalcost);
    formdata.append("ivp_currency", "SAR");
    formdata.append("ivp_test", "1");
    formdata.append("ivp_cart", orderData._id);
    formdata.append("ivp_desc", "Buying Goods");
    formdata.append("return_auth", "https://yksb.sa/auth1");
    formdata.append("return_decl", "https://yksb.sa/auth2");
    formdata.append("return_can", "https://yksb.sa/auth3");
    formdata.append("bill_fname", orderData.name);
    formdata.append("bill_sname", orderData.name);
    formdata.append("bill_addr1", orderData.address);
    formdata.append("bill_phone", orderData.phone);
    formdata.append("bill_city", "raide");
    formdata.append("bill_country", "SA");
    formdata.append("bill_email", orderData.email);
    formdata.append("ivp_lang", "ar");
    formdata.append("ivp_framed",1)

    var requestOptions = {
    method: 'POST',
    
    body: formdata,
    redirect: 'follow'
    };

    fetch("https://secure.telr.com/gateway/order.json", requestOptions)
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
        /*
        order: {ref: "0E89F7CA5C798CFDD2C903E53585A34DEBEC122A4CF1CCBCAF2B8BF6D36F1EF0", url: "https://secure.telr.com/gateway/process.html?o=0E8…DD2C903E53585A34DEBEC122A4CF1CCBCAF2B8BF6D36F1EF0"}
trace: "4001/27331/6053ef1a"*/
    })
    .then((jsonResponse)=>{
        setTelrOrder(jsonResponse)
        console.log(jsonResponse) // here we can catch the response
    })
    .catch((error)=>{
        //console.log(error)
    })
    // .then(response => response.text())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
}
const handleClose = ()=>{
    setShowModal(false)
}
return (
    <Layout title="الدفع" description="الدفع" className="container">
            
            
    
            <section className="breadcrumb_area breadcrumb1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                    <h4 className="page_title">الدفع </h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                             <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"> الدفع</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {
                    isAuthenticated() !== false &&
                    <section className="checkout-wrapper p-top-90 p-bottom-110">
                    <div className="container">
                        <div className="row">    
                            <div className="col-lg-12 col-md-12 mb-4">
                                
                                {
                                    checkoutMessage && 
                                    <Alert variant="danger">
                                        {checkoutMessage}
                                    </Alert>
                                } 
                            </div> 
                            <div className="col-lg-12 col-md-12 mb-12">
                                {/* <div className="card mb-3">
                                    <div className="card-body">
                                    <h4 className="color-primary">ملخص الطلب</h4>
                                     */}

                                <div className="widget-wrapper">
                                    <div className="widget-default mb-4">
                                        <div className="widget-header">
                                            <h4 className="widget-title">ملخص الطلب</h4>
                                            {finishOrder} 
                                        </div>
                                        <div className="widget-content p-0">
                                            

                                                <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="4"><i className="la la-angle-left"></i> عنوان الشحن</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>الاسم</td>
                                                        <td>{orderData.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>الدولة</td>
                                                        <td>{country}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>المدينة</td>
                                                        <td>{city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>العنوان</td>
                                                        <td>{orderData.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>الجوال</td>
                                                        <td>{orderData.phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan="2"><i className="la la-angle-left"></i> تفاصيل الطلب</th>
                                                    </tr>
                                                    <tr>
                                                        <td> حالة الطلب</td>
                                                        <td>
                                                        {orderData.order_status === "OnProgress" && <Badge pill variant="primary p-2">جاري العمل عليه</Badge>}
                                                        {orderData.order_status === "WaitingPayment" && <Badge pill variant="info p-2">في انتظار الدفع</Badge>}
                                                        {orderData.order_status === "Delivered" && <Badge pill variant="success p-2">تم التسليم</Badge>}
                                                        {orderData.order_status === "Canceled" && <Badge pill variant="danger p-2">تم الالغاء</Badge>}
                                                        </td>
                                                    </tr>
                                                    {
                                                        orderShipping !== null && 
                                                        <tr>
                                                            <td>شركة الشحن</td>
                                                        <td>{orderShipping.shipping_compnay}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        orderShipping !== null && 
                                                        <tr>
                                                            <td> تكلفة الشحن</td>
                                                        <td>{orderShipping.shipping_price} <Currency/></td>
                                                        </tr>
                                                    }
                                                    </tbody>
                                                </table>


                                                
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">صورة المنتج</th>
                                                            <th className="text-center">المنتج</th>
                                                            <th>المواصفات </th>
                                                            <th className="text-center">الكمية</th>
                                                            <th className="text-center">السعر</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            order_items.map((items,index)=>{
                                                                return (
                                                                    <tr className="cart-single ml-2">
                                                                        <td className="p-2 text-center">
                                                                            
                                                                                {
                                                                                    items.cartproduct[0].productImages.length > 0 && 
                                                                                    <img width={80} src={UPLOADURL + "/" + items.cartproduct[0].productImages[0].product_image} alt=""/>
                                                                                }
                                                                              
                                                                           
                                                                        </td>
                                                                        <td className="p-2 text-center">
                                                                           
                                                                     
                                                                                {items.cartproduct[0].product_title}
                                                                            
                                                                        </td>
                                                                        <td className="p-2">
                                                                            <div className="quantity">
                                                                                <div className="total-item">
                                                                                    {items.product_attribute_fiels.length === 0 && <span>لا يوجد</span>}
                                                                                    {
                                                                                        items.product_attribute_fiels && 
                                                                                        items.product_attribute_fiels.map((att,index)=>{
                                                                                            if(att.attribute_type === "Image"){
                                                                                                return <div className="quantity mb-2"><img width={50} src={UPLOADURL + "/" + att.attribute_value} alt=""/></div>
                                                                                            }
                                                                                            else if(att.attribute_type === "Text"){
                                                                                                return <div className="quantity mb-2">{att.attribute_value}</div>
                                                                                            }
                                                                                            else if(att.attribute_type === "Color"){
                                                                                                return <div className="quantity mb-2 colborder" style={{width:30,height:30,backgroundColor:att.attribute_value,borderRadius:30}}><span> </span></div>
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="quantity text-center">
                                                                                <div className="total-item">
                                                                                <span>{items.product_quantity}</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="subtotal text-center">
                                                                                <span>{(items.product_price * items.product_quantity).toFixed(2)} <Currency/></span>
                                                                            </div>
                                                                        </td>
                                                                      
                                                                    </tr>
            
                                                                )
                                                            })
                                                        }
                                                    
                                                  
                                                        <tr className="cart-bottom">
                                                            <td colspan="5">
                                                                {/* <div className="total-amount">
                                                                    الشحن: <span>$50.00</span>
                                                                </div>
                                                            
                                                                <div className="total-amount">
                                                                    القيمة المضافة: <span>$7.00</span>
                                                                </div> */}
                                                                {discount > 0 && <div className="total-amount">
                                                                    الخصم لاستخدام كوبون: <span>{discount} <Currency/></span>
                                                                </div>}    
                                                                <h6 className="total-amount text-right mt-2">
                                                                    الإجمالي: <span>{orderData.totalcost - discount} <Currency/></span>
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            </div>
                                           



                                    </div>
                                </div>
                            </div>
                            
                            {
                                orderData.order_status === "WaitingPayment" && 
                                <div className="col-lg-12 col-md-12 mb-12">
                        

                               <div className="widget-wrapper">
                                    <div className="widget-default">
                                        <div className="widget-header">
                                            <h4 className="widget-title">وسائل الدفع</h4>
                                        </div>
                                        <div className="widget-content p-0">
                                        {paymentBanks.length > 0 && <h6 className="pt-2 pr-4 mb-3 mt-3"><i className="fa fa-university color-secondary fa-lg"></i> حوالة بنكية </h6> }

                                    <div className="table-responsive">
                                            {
                                                paymentBanks.length > 0 &&
                                                <table className="table mb-4">
                                                <thead>
                                                    <tr>
                                                        <th>اختر</th>
                                                        <th>البنك</th>
                                                        <th>المعرف البديل</th>
                                                        <th>الاسم</th>
                                                        <th>رقم الحساب</th>
                                                        <th>رقم الايبان</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        paymentBanks.map((pay,index)=>{
                                                            return (
                                                                <tr>
                                                                    <td><input type="Radio" onClick={()=>setSelectedPayment(pay)} name="payments"/></td>
                                                                    <td>{pay.payment_info.bank_title}</td>
                                                                    <td>{pay.payment_info.bank_account_phone}</td>
                                                                    <td>{pay.payment_info.bank_account_owner}</td>
                                                                    <td>{pay.payment_info.bank_account_number}</td>
                                                                    <td>{pay.payment_info.bank_account_ipan}</td>
                                                                    
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td colSpan={2}>صورة ايصال الدفع</td>
                                                        <td colSpan={4} className="paymentimg"><ImageUploader
                                                                        {...props}
                                                                        withIcon={false}
                                                                        onChange={onDrop}
                                                                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                                                        maxFileSize={5242880}
                                                                        singleImage={true}
                                                                            buttonText={"اختر صورة لرفعها"}
                                                                            label = {" اقصي حجم للصورة 5 ميجا "}
                                                                            fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                                                            fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                                                        />
                                                                        {imageMessage1}
                                                        </td>
                                                    </tr>
                                                   
                                                    
                                                </tbody>
                                            </table>
                                            }
                                            
                                            {paymentStcPay !== null && <div className="pt-2 pr-4 mb-3 mt-4 topborder"> <img width={80} src={stc} alt="StcPay"/> </div>}
                                            {paymentStcPay !== null && 
                                            <div className="table-responsive">
                                                <table className="table mb-4">
                                                    <thead>
                                                        <tr>
                                                            <th>اختر</th>
                                                            <th>كود التاجر</th>
                                                            <th>صورة ايصال الدفع</th>
                                        
                                                        </tr>
                                                        
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                            <td><input type="Radio" onClick={()=>setSelectedPayment(paymentStcPay)} name="payments" /></td>
                                                            <td><img width={100} src={UPLOADURL + "/" + paymentStcPay.payment_info.store_code} alt="" /></td>
                                                            <td className="paymentimg"><ImageUploader
                                                                            {...props}
                                                                            withIcon={false}
                                                                            onChange={onDrop2}
                                                                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                                                            maxFileSize={5242880}
                                                                            singleImage={true}
                                                                                buttonText={"اختر صورة لرفعها"}
                                                                                label = {" اقصي حجم للصورة 5 ميجا "}
                                                                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                                                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                                            />
                                                            {imageMessage2}
                                                            </td>
                                                                        
                                                        </tr>
                                                       
                                                     

                                                    </tbody>
                                                    
                                                </table>
                                            </div>
                                            }

                                            {paymentCashOnDelivery !== null && <h6 className="pt-4 pr-4 mb-3 mt-4 topborder"><i className="fa fa-credit-card color-secondary" aria-hidden="true"></i> الدفع عند الاستلام </h6>}
                                            {paymentCashOnDelivery !== null && 
                                            <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>اختر</th>
                                                                <th>طريقة الدفع</th>
                                                            </tr>
                                                            <tr>
                                                                <td><input type="Radio" onClick={()=>setSelectedPayment(paymentCashOnDelivery)} name="payments"/></td>
                                                                <td>الدفع عند الاستلام</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            </div>
                                            }

                                            {/* //Visi  */}
                                            {paymentMoyasar !== null && <h6 className="pt-4 pr-4 mb-2 mt-4 topborder"><i className="fa fa-credit-card color-secondary" aria-hidden="true"></i> بطاقة بنكية </h6>}
                                            {paymentMoyasar !== null &&
                                                <div className="table-responsive">
                                                    <div className="pr-4 pb-2"> <img width={50} src={mada} alt="Mada"/> <img width={50} src={sadad} alt="Sadad"/> <img width={50} src={visa} alt="Visa"/> <img width={50} src={master} alt="Master Card"/> 
                                                    {/* <img width={50} src={apple} alt="Apple Pay"/> */}
                                                    </div>
                                                    <table className="table">
                                                        <tbody>
                                                    
                                                            <tr>
                                                                <th>اختر</th>
                                                                <th>طريقة الدفع</th>
                                                            </tr>
                                                        
                                                            <tr>
                                                                <td>
                                                                    <input type="Radio" onClick={()=>setSelectedPayment(paymentMoyasar)} name="payments"/>
                                                                </td>
                                                                <td>بطاقة بنكية</td>
                                                            </tr>
                                                            <tr>
                                                                <td>الإسم على البطاقة</td>
                                                                <td className="form-inline">
                                                                    <input value={v_name} onChange={(e)=>set_v_name(e.target.value)} type="text" name="source[name]" className="form-control form-control" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>رقم البطاقة</td>
                                                                <td className="form-inline">
                                                                    <input type="text" value={v_card} onChange={(e)=>set_v_card(e.target.value)} name="source[number]" className="form-control form-control" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>تاريخ الإنتهاء</td>
                                                                <td className="form-inline"> <div className="mr-2">الشهر</div> <input type="text" value={v_month} onChange={(e)=>set_v_month(e.target.value)} name="source[month]"  className="form-control form-control mr-2" placeholder="MM" /> <div className="mr-2">السنة</div> <input type="text" value={v_year} onChange={(e)=>set_v_year(e.target.value)} name="source[year]"  className="form-control form-control mr-2" placeholder="YY" /> </td>
                                                            </tr>
                                                            <tr>
                                                                <td>الرقم السري CVC</td>
                                                                <td className="form-inline">
                                                                    <input type="text" value={v_cvc} onChange={(e)=>set_v_cvc(e.target.value)} name="source[cvc]" placeholder="xxx" className="form-control form-control" />
                                                                    </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    
                                         
                                                </div>  
                                            }
                                                
                                                {/* //Visi End  */}

                                                <Modal size="lg" show={showModal} onHide={handleClose}>
                                                    {
                                                        JSON.stringify(telrOrder) !== JSON.stringify({}) && !telrOrder.order.error && 
                                                        <Modal.Body style={{height:800}}><iframe style={{height:600}} src={telrOrder.order.url}/></Modal.Body>
                                                    }
                                                    
                                                </Modal>
                                            </div>
                                    </div>
                           
                                        </div>
                                    </div>




                                    <div className="float-left">    
                                        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                            <Toast.Body className="text-danger"> {checkoutMessage}</Toast.Body>
                                        </Toast>
                                     </div>  


                                    <div className="action-btns d-flex m-top-60">
                                        <button onClick={()=>RequestProcess()} disabled={orderButtonStatus} className="btn btn-primary mr-auto btn-icon icon-left">تنفيذ <i className="la la-angle-left"></i></button>
                                    </div> 
                                </div>
                            }
                            
                               
                        </div>
                 

                        

                

                </div>
                </section>
                }



        </Layout>
    )
}

export default Order