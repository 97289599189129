/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signUp,getCountries} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated} from '../../api/Auth'
const Register = ({history})=>{

    const [values,setValues] = useState({
        email:'',
        password:'',
        name:'',
        phone:'',
        username:'',
        address:'',
        error:'',
        loading:false,
        redirectToReferrer:false
    })
    const [countries,setCountries]       = useState([])
    const [cities,setCities]             = useState([])
    const [selectedCountry,set_selectedCountry] = useState('')
    const [selectedCity,set_selectedCity] = useState('')
    const emailValue = (value)=>{
        setValues({...values,email:value.target.value})
    }
    const passwordValue = (value)=>{
        setValues({...values,password:value.target.value})
    }
    const nameValue = (value)=>{
        setValues({...values,name:value.target.value})
    }
    const usernameValue = (value)=>{
        setValues({...values,username:value.target.value})
    }
    const phoneValue = (value)=>{
        setValues({...values,phone:value.target.value})
    }
    const addressValue = (value)=>{
        setValues({...values,address:value.target.value})
    }
    const loadCountries = ()=>{
        getCountries().then((data)=>{
            setCountries(data)
            
            //set city
            if(data.length > 0){
                set_selectedCountry(data[0]._id)
                if('children' in data[0]){
                    setCities(data[0].children)
                    set_selectedCity(data[0].children[0]._id);
                }
            }
        })
    }

    
    useEffect(()=>{
        loadCountries()
    },[])
    const changeCountry = (value)=>{
        set_selectedCountry(value)
        countries.forEach(element => {
            if(element._id === value){
                setCities(element.children)
                if(element.children.length)
                    set_selectedCity(element.children[0]._id)
                else
                    set_selectedCity('')
            }
        });
    }
    const changeCity = (value)=>{
        set_selectedCity(value)
    }
    const singInSubmit = ()=>{
        const email = values.email
        const password = values.password
        const phone = values.phone
        const username = values.username
        const name = values.name
        const address = values.address
        
        if(email !== '' && password !== '' && phone !== '' && name !== '' && address !== '' && selectedCountry !== '' && selectedCity !== ''){
            setValues({...values,loading:true})
            signUp({name:name,username:'',phone:phone,email:email,password:password,country:selectedCountry,city:selectedCity,address:address})
            .then((data)=>{
                console.log(data)
                if(data.error){
                    setValues({...values,error:'البريد الإلكتروني غير متاح',loading:false})
                }
                else{
                    //Store 
                    history.push('/signin')
                    // authenticate(data,()=>{
                    //     setValues({
                    //         ...values,
                    //         redirectToReferrer:true 
                    //     })
                    // })
                }
            })
        }
    }
    const redirectUser = ()=>{
        if(isAuthenticated()){
            return <Redirect to="/" />
        }
    }
    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
            {redirectUser()}
            <div className="login-form d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-xl-6 p-top-130 p-bottom-130 center-c">
                            <div className="form-wrapper">
                                <div className="card card-shadow">
                                    <div className="card-header">
                                        <h4 className="text-center mb-3 mt-3">انشاء حساب جديد</h4>
                                    </div>
                                    <div className="card-body">
                                    <p className="text-center color-danger">{values.error}</p>
                                        <form class="was-validated"> 
                                            <div className="form-group">
                                                <input required onChange={(text)=>emailValue(text)} type="text" placeholder="البريد الإلكتروني" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <input required onChange={(text)=>passwordValue(text)} type="password" placeholder="كلمة المرور" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <input required onChange={(text)=>nameValue(text)} type="text" placeholder="الإسم" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <input required onChange={(text)=>phoneValue(text)} type="text" placeholder="الجوال" className="form-control" />
                                            </div>

                                            <div class="form-group was-validated">
                                                <div class="select-basic">
                                                    <select class="form-control" onChange={(e)=>changeCountry(e.target.value)} value={selectedCountry}>
                                                        {
                                                            countries.map((contry,index)=>{
                                                                return (<option value={contry._id}>{contry.country_title}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group was-validated"> 
                                                <div class="select-basic">
                                                    <select class="form-control" onChange={(e)=>changeCity(e.target.value)} value={selectedCity}>
                                                        {
                                                            cities.map((city,index)=>{
                                                                return (<option value={city._id}>{city.country_title}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group was-validated"> 
                                                <input onChange={(e)=>addressValue(e)} required type="text" placeholder=" العنوان" className="form-control" required/>
                                            </div>

                                            {/* <div className="form-group">
                                                <input required onChange={(text)=>usernameValue(text)} type="text" placeholder="إسم المستخدم" className="form-control" />
                                            </div> */}
                                            <div className="form-group text-center m-bottom-20">
                                                {values.loading === false && 
                                                    <button  onClick={()=>singInSubmit()} className="btn btn-secondary" type="button">التسجيل</button>
                                                }
                                            </div>
                                        </form>
                                        {/* <p className="text-center m-bottom-25">Don't you have an account? <a href="">Register</a></p> */}
                                        <div className="d-flex other-logins justify-content-center">
                                            {/* <a href=""><span className="fab fa-facebook-f"></span> Facebook</a>
                                            <a href=""><span className="fab fa-google-plus-g"></span> Google</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )    
}
export default Register;