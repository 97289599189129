/* eslint-disable */
import React,{useState,useEffect} from 'react'
import Layout from '../Layout'
import {signIn} from '../../api/apiCore'
import {Redirect} from 'react-router-dom'
import {authenticate,isAuthenticated,signout,EditLocalStorage} from '../../api/Auth'
import {Card} from 'react-bootstrap';
import {Table} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import {getCustomerInfo,updateCustomerInfo} from '../../api/apiCore'
import {Toast} from 'react-bootstrap'

const Profile = ({history})=>{
    const [customerInfo,setCustomerInfo] = useState({})
    const loadCustomerInfo = ()=>{
        getCustomerInfo().then((data)=>{
            console.log(data)
            setCustomerName(data.name)
            setCustomerPhone(data.phone)
            setCustomerInfo(data)
        })
    }
    useEffect(()=>{
        if(isAuthenticated() === false)
        history.push('/')
        loadCustomerInfo()
    },[])
    const [show, setShow] = useState(false);
    const [errorshow, seterrorShow] = useState(false);

    const [customerName,setCustomerName] = useState()
    const [customerPhone,setCustomerPhone] = useState()

    const changeName  = (e)=>{
        setCustomerName(e)
    }
    const changePhone = (e)=>{
        setCustomerPhone(e)
    }

    const submitChanges = ()=>{
        if(customerName && customerPhone){
            updateCustomerInfo({name:customerName,phone:customerPhone}).then((data)=>{
                console.log(data)
                setShow(true)
                loadCustomerInfo()
                EditLocalStorage({name:customerName,phone:customerPhone})
            })
        }
    }

    return (
        <Layout title="Home Page" description="Home Page Description" className="container">
             <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                            <h4 className="page_title">حسابي</h4>
                                 <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">الرئيسية</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">تعديل بياناتي</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="products-wrapper p-top-60 p-bottom-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 order-0 order-md-0 mb-4">
                                <div className="sidebar">
                                  
                                    <div className="widget-wrapper">
                                        <div className="widget-default">
                                            <div className="widget-header">
                                                <h6 className="widget-title">القائمة</h6>
                                            </div>
                                            <div className="widget-content pr-0 pl-0">
                                                <div className="category-widget">
                                                    <div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/dashboard">صفحة حسابي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/profile">تعديل بياناتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/orders">قائمة طلباتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/wishlist">قائمة أمنياتي</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/address">تعديل عنواني</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link to="/password">كلمة المرور</Link> </div>
                                                        <div className="dropdown-item text-right"><i className="la la-angle-left"></i> <Link onClick={()=>signout(()=>{history.push('/')})} style={{cursor:'pointer'}}>تسجيل الخروج</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                               
                            
                          
                                <div className="row">
                                    <div className="col-lg-12 col-md-11 mb-4">
                                        <div className="sidebar">
                                        
                                            <div className="widget-wrapper">
                                                <div className="widget-default">
                                                    <div className="widget-header">
                                                        <h6 className="widget-title">تعديل بياناتي</h6>
                                                    </div>
                                                    <div className="widget-content">
                                                        <div className="category-widget">
                                                            
                                                           
                                                            
                                                                    <div className="form-group was-validated">
                                                                   <Form.Label>الإسم</Form.Label>
                                                                        <input value={customerName} onChange={(e)=>changeName(e.target.value)} type="text" placeholder="الإسم" className="form-control" required/>
                                                                    </div>
                                                                    <div className="form-group was-validated">
                                                                    <Form.Label>الجوال</Form.Label>
                                                                        <input value={customerPhone} onChange={(e)=>changePhone(e.target.value)} type="text" placeholder="الجوال" className="form-control" required/>
                                                                    </div>
                                                                   
                                                                    <div className="form-group text-center m-bottom-20">

                                                                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                                                                        <Toast.Body className="text-success">تم حفظ التعديلات بنجاح!</Toast.Body>
                                                                    </Toast>

                                                                        <button  onClick={()=>submitChanges()} className="btn btn-secondary" type="button">حفظ التعديل</button>
                                                                    </div>                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                            </div>

                          


                        </div>
                    </div>
                </section>

        </Layout>
    )    
}
export default Profile;