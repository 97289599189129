/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {Helmet} from "react-helmet";

import './assets/vendor_assets/css/bootstrap/bootstrap.css'
import './assets/vendor_assets/css/animate.css'
import './assets/vendor_assets/css/brands.css'
import './assets/vendor_assets/css/fontawesome.min.css'
import './assets/vendor_assets/css/fontello.css'
import './assets/vendor_assets/css/jquery-ui.css'
import './assets/vendor_assets/css/jquery.mb.YTPlayer.min.css'
import './assets/vendor_assets/css/line-awesome.min.css'
import './assets/vendor_assets/css/lnr-icon.css'
import './assets/vendor_assets/css/magnific-popup.css'
import './assets/vendor_assets/css/navigation.css'
import './assets/vendor_assets/css/owl.carousel.min.css'
import './assets/vendor_assets/css/select2.min.css'
import './assets/vendor_assets/css/settings.css'
import './assets/vendor_assets/css/slick.css'
import './assets/vendor_assets/css/trumbowyg.min.css'
import './assets/style.css'
import './assets/style-rtl.css'
import Footer from './components/Footer';
import Header from './components/Header';

import {Card,Button,Jumbotron,Container} from 'react-bootstrap'
import {generalConfigrations,getStaticPages} from '../api/apiCore';
import {UPLOADURL} from '../config'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";

const CloseLayout = ({title='Title',description = 'Description',className,children})=>{
    const [siteConfigrations,setSiteConfigrations] = useState({})
    const [staticPages,setStaticPages]             = useState([])
    const loadSiteConfigrations = ()=>{
        generalConfigrations().then((data)=>{
            setSiteConfigrations(data)
        })
    }

    const loadStaticPages = ()=>{
        getStaticPages().then((data)=>{
            setStaticPages(data)
        })
    }

    useEffect(()=>{
        loadSiteConfigrations()
        loadStaticPages()
    },[])
    return (
        <React.Fragment key="fragment1">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={siteConfigrations.shop_discription} />
                <link rel="icon" type="image/jpg" href={UPLOADURL + "/" + siteConfigrations.shop_logo} sizes="16x16" />
                <title>{siteConfigrations.shop_title}</title> 

            </Helmet>
            {
                "shop_status" in siteConfigrations && siteConfigrations.shop_status === true && <Redirect to="/"/>
            }
            
            
            <Container className="closingmessage m-bottom-150 m-top-150">
            <Jumbotron fluid>
                <Container>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 text-center"><i class="las la-store"></i></div>
                    <div className="col-lg-6 col-md-6 col-sm-6"><h1>المتجر مغلق حالياً</h1>
                    {siteConfigrations.shop_closing_message}
                    </div>
                </div>
                </Container>
            </Jumbotron>
            </Container>

            {children}
        </React.Fragment>
    )
} 

export default CloseLayout