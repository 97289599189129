import React from 'react';
import Routes from './Routes'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

function App() {
  return (<Routes/>)
}

export default App
